var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
/** Helper methods for creating gradients. */
export var Gradients;
(function (Gradients) {
    Gradients.debug = [["red", 0.0], ["green", 0.5], ["blue", 1.0]];
    function shade(alpha) {
        if (alpha === void 0) { alpha = 0.2; }
        var color = "#000000".color().alpha(alpha);
        return [
            [color.string(), 0.0],
            ["#00000000", 1.0]
        ];
    }
    Gradients.shade = shade;
    /** Cylidrical shade. */
    function cylinder(base, delta) {
        if (delta === void 0) { delta = 5; }
        return [
            [base.l(base.l() + delta).string(), 0.0],
            [base.l(base.l() + 3 * delta).string(), 0.3],
            [base.string(), 0.6],
            [base.l(base.l() - delta).string(), 0.8],
            [base.l(base.l() + 2 * delta).string(), 1.0],
        ];
    }
    Gradients.cylinder = cylinder;
    /**
     * Suitable for a simple box, front view.
     * @param opts.shift Value from 0 to 1
     * @param opts.delta Value from 0 to 255.
     */
    function box(base, opts) {
        var _a, _b;
        var delta = (_a = opts === null || opts === void 0 ? void 0 : opts.delta) !== null && _a !== void 0 ? _a : 15;
        var shift = (_b = opts === null || opts === void 0 ? void 0 : opts.shift) !== null && _b !== void 0 ? _b : 0.15;
        return [
            [base.l(base.l() + delta).string(), 0.0],
            [base.string(), shift],
            [base.string(), 1 - shift],
            [base.l(base.l() - delta).string(), 1.0],
        ];
    }
    Gradients.box = box;
    function reflected() {
        var stops = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            stops[_i] = arguments[_i];
        }
        return __spreadArray(__spreadArray([], stops.map(function (stop) { return [stop[0], stop[1]]; })), stops.reverse().map(function (stop) { return [stop[0], 1.0 - stop[1]]; }));
    }
    Gradients.reflected = reflected;
    /** Simple gradient. Lighter at start, darker at end if range > 0 */
    function simple(base, range) {
        if (range === void 0) { range = 10; }
        return [
            [base.l(base.l() + range / 2).string(), 0.0],
            [base.l(base.l() - range / 2).string(), 1.0],
        ];
    }
    Gradients.simple = simple;
    function fade(base, opts) {
        var _a, _b;
        return [
            [base.string(), (_a = opts === null || opts === void 0 ? void 0 : opts.offset) !== null && _a !== void 0 ? _a : 0.0],
            [base.alpha((_b = opts === null || opts === void 0 ? void 0 : opts.finalAlpha) !== null && _b !== void 0 ? _b : 0.0).string(), 1.0],
        ];
    }
    Gradients.fade = fade;
    /** Scale the points, towards anchor */
    function scalePoints(stops, scale, anchor) {
        return stops.map(function (_a) {
            var color = _a[0], stop = _a[1];
            return [color, (stop - anchor) * scale + anchor];
        });
    }
    Gradients.scalePoints = scalePoints;
})(Gradients || (Gradients = {}));
