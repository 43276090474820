import { mirrorX, mirrorY, setDefaultStyleWithStroke } from "apparatus/draw";
import { Group, Path, Shape } from "paper";
import { P } from "./common";
/**
 * This is a special type of apparatus, used for development only!
 * It does not represent any specific apparatus, instead it
 * allows its own path segments to be edited live. This is useful
 * when trying to draw new apparatus, speeds up the process by
 * allowing the developer to see the path as the path is edited.
 *
 * Note that when specifying the path segments,
 * only numbers can be used, variables cannot be used.
 */
var LiveEditApparatus = /** @class */ (function () {
    function LiveEditApparatus() {
    }
    LiveEditApparatus.prototype.render = function (appearance) {
        var isValid = false;
        try {
            var rawSegmentsString = appearance.segments;
            // Remove newlines, then surround with brackets to create an array.
            var segmentsString = "[" + rawSegmentsString.replace("\n", "") + "]";
            var evalSegments = eval(segmentsString);
            var segments_2 = evalSegments;
            // Validate each segment.
            for (var _i = 0, segments_1 = segments_2; _i < segments_1.length; _i++) {
                var segment = segments_1[_i];
                if (segment.length < 1 || segment.length > 3) {
                    throw new Error("Contains invalid segment");
                }
                // Validate each point.
                for (var _a = 0, segment_1 = segment; _a < segment_1.length; _a++) {
                    var point = segment_1[_a];
                    if (point !== undefined && point.length != 2) {
                        throw new Error("Segment contains invalid point");
                    }
                }
            }
            isValid = true;
            this.lastValidSegments = segments_2;
        }
        catch (_b) {
            // Input is invalid.
        }
        var segments = this.lastValidSegments;
        var path = new Path(segments);
        if (appearance.strokeThickness != "none") {
            setDefaultStyleWithStroke(appearance.strokeThickness, path);
        }
        if (appearance.mirroredY) {
            mirrorY(path);
        }
        if (appearance.mirrored) {
            mirrorX(path);
        }
        if (appearance.closed) {
            path.fillColor = "#dddddd80";
            path.closePath();
        }
        var graphic = new Group([path]);
        if (appearance.xray) {
            // Show the path and control points in yellow.
            path.fullySelected = true;
            path.selectedColor = "#deb018";
        }
        if (appearance.highlightFirstAndSecond) {
            // Mark first and second points.
            if (segments.length >= 2) {
                var marker = Shape.Circle(P(segments[0][0][0], segments[0][0][1]), 6.5);
                marker.fillColor = "black";
                graphic.addChild(marker);
            }
            if (segments.length >= 3) {
                var marker = Shape.Circle(P(segments[1][0][0], segments[1][0][1]), 5.0);
                marker.fillColor = "black";
                graphic.addChild(marker);
            }
        }
        // Draw center.
        graphic.addChild(Shape.Circle(P(0, 0), 2).withFill("green"));
        if (!isValid) {
            graphic.strokeColor = "red";
        }
        var hitShape = graphic.bounds.toShape();
        return {
            graphic: graphic,
            hitShape: hitShape,
            pivot: appearance.lockPivot ? P(0, 0) : undefined
        };
    };
    LiveEditApparatus.properties = {
        label: "Live-Edit",
        defaultAppearance: {
            segments: "[[0, 0]],\n[[100, 0]],\n[[100, 100]],\n[[0, 100]]",
            closed: true,
            mirrored: false,
            mirroredY: false,
            xray: true,
            highlightFirstAndSecond: false,
            strokeThickness: "default",
            lockPivot: true,
        },
        appearanceSpecs: [
            {
                spec: "textarea", key: "segments", label: "Segments",
            },
            {
                spec: "checkbox-group", label: "Options", checkboxes: [
                    { key: "closed", label: "Close path" },
                    { key: "mirrored", label: "Mirrored (X)", },
                    { key: "mirroredY", label: "Mirrored (Y)", },
                    { key: "xray", label: "Show X-ray" },
                    { key: "highlightFirstAndSecond", label: "Highlight 1st and 2nd points" },
                    { key: "lockPivot", label: "Lock Pivot" },
                ]
            },
            {
                spec: "radio", key: "strokeThickness", label: "Stroke thickness",
                options: [
                    { value: "default", label: "Default" },
                    { value: "thin", label: "Thin" },
                    { value: "none", label: "None" },
                ],
            }
        ],
    };
    return LiveEditApparatus;
}());
export { LiveEditApparatus };
