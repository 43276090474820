import Flags from "flags";
import { Consts } from "vars";
var apparatusSpecList = [
    {
        type: "__live edit__",
        category: "Containers",
        sprite: ["2", 1, 0],
        released: Consts.isDev,
    },
    {
        type: "test tube",
        category: "Containers",
        sprite: [0, 0],
    },
    {
        type: "test tube with side arm",
        category: "Containers",
        sprite: [1, 9],
    },
    {
        type: "bung",
        category: "Containers",
        sprite: [9, 0],
    },
    {
        type: "beaker",
        category: "Containers",
        sprite: [0, 2],
    },
    {
        type: "displacement beaker",
        category: "Containers",
        sprite: [0, 3],
    },
    {
        type: "conical flask",
        category: "Containers",
        sprite: [0, 4],
    },
    {
        type: "conical flask with side arm",
        category: "Containers",
        sprite: [4, 7],
    },
    {
        type: "boiling flask",
        category: "Containers",
        sprite: [0, 5],
    },
    {
        type: "volumetric flask",
        category: "Containers",
        sprite: [0, 6],
    },
    {
        type: "round bottom flask",
        category: "Containers",
        sprite: [0, 7],
    },
    {
        type: "petri dish",
        category: "_Bio_Equipment",
        sprite: [0, 8],
    },
    {
        type: "petri top",
        category: "_Bio_Equipment",
        sprite: ["2", 3, 3],
    },
    {
        type: "choice chamber",
        category: "_Bio_Equipment",
        sprite: ["2", 9, 4],
    },
    {
        type: "watch glass",
        category: "Containers",
        sprite: [2, 9],
    },
    {
        type: "gas jar",
        category: "Containers",
        sprite: [0, 9],
    },
    {
        type: "tank",
        category: "Containers",
        sprite: [0, 10],
    },
    {
        type: "ice bath",
        category: "Containers",
        sprite: ["3", 4, 7],
    },
    {
        type: "boiling tube",
        category: "Containers",
        sprite: [0, 0],
        tier: "boost",
    },
    {
        type: "boiling tube with sidearm",
        category: "Containers",
        sprite: [1, 9],
        tier: "boost",
    },
    {
        type: "thiele tube",
        category: "Containers",
        sprite: [0, 1],
    },
    {
        type: "pear-shaped flask",
        category: "Containers",
        sprite: ["2", 0, 2],
        tier: "boost",
    },
    {
        type: "schlenk flask",
        category: "Containers",
        sprite: ["2", 0, 4],
    },
    {
        type: "weight boat",
        category: "Containers",
        sprite: ["2", 9, 6],
    },
    {
        type: "eureka",
        category: "Containers",
        sprite: ["3", 2, 2],
    },
    {
        type: "calorimetry cup",
        category: "Containers",
        sprite: [6, 6],
    },
    {
        type: "thermos",
        category: "Containers",
        sprite: ["2", 6, 5],
    },
    {
        type: "cuvette",
        category: "Containers",
        sprite: [6, 7],
    },
    {
        type: "autosampler vial",
        category: "Containers",
        sprite: ["3", 5, 9],
    },
    {
        type: "cell flask",
        category: "Containers",
        sprite: ["2", 6, 0],
        tier: "boost"
    },
    {
        type: "centrifuge tube",
        category: "_Bio_Equipment",
        sprite: [8, 6],
        tier: "boost",
    },
    {
        type: "eppendorf tube",
        category: "_Bio_Equipment",
        sprite: [8, 5],
        tier: "boost",
    },
    {
        type: "wash bottle",
        category: "Containers",
        sprite: [6, 8],
    },
    {
        type: "half-cell tube",
        category: "Containers",
        sprite: ["3", 5, 7],
    },
    {
        type: "cylinder",
        category: "Measurement",
        sprite: [1, 1],
    },
    {
        type: "pipette",
        category: "Measurement",
        sprite: [1, 3],
    },
    {
        type: "mohr",
        category: "Measurement",
        sprite: ["2", 5, 6],
    },
    {
        type: "burette",
        category: "Measurement",
        sprite: [1, 4],
    },
    {
        type: "eudiometer",
        category: "Measurement",
        sprite: ["3", 3, 5],
    },
    {
        type: "thermometer",
        category: "Measurement",
        sprite: [1, 0],
    },
    {
        type: "digital thermometer",
        category: "Measurement",
        sprite: [7, 6],
    },
    {
        type: "balance",
        category: "Measurement",
        sprite: [1, 5],
    },
    {
        type: "triple beam balance",
        category: "Measurement",
        sprite: ["2", 8, 7],
        tier: "boost",
    },
    {
        type: "beam balance",
        category: "Measurement",
        sprite: ["2", 8, 9],
        tier: "boost",
    },
    {
        type: "dynamometer",
        category: "Measurement",
        sprite: ["3", 4, 2],
    },
    {
        type: "pycnometer",
        category: "Measurement",
        sprite: ["3", 4, 5],
    },
    {
        type: "hydrometer",
        category: "Measurement",
        sprite: ["2", 6, 8],
    },
    {
        type: "manometer",
        category: "Measurement",
        sprite: ["2", 7, 1],
        tier: "boost",
    },
    {
        type: "ruler",
        category: "Measurement",
        sprite: [1, 7],
    },
    {
        type: "tape measure",
        category: "Measurement",
        sprite: ["2", 2, 6],
    },
    {
        type: "vernier",
        category: "Measurement",
        sprite: ["2", 9, 9],
        tier: "boost",
    },
    {
        type: "protractor",
        category: "Measurement",
        sprite: ["2", 8, 8],
    },
    {
        type: "ph paper pack",
        category: "Measurement",
        sprite: ["2", 7, 8],
        tier: "boost",
    },
    {
        type: "ph paper",
        category: "Measurement",
        sprite: ["2", 7, 9],
    },
    {
        type: "ph probe",
        category: "Measurement",
        sprite: [6, 9],
    },
    {
        type: "litmus paper",
        category: "Measurement",
        sprite: ["3", 5, 2],
    },
    {
        type: "conductimeter",
        category: "Measurement",
        sprite: [6, 10],
        tier: "boost"
    },
    {
        type: "oxsat probe",
        category: "Measurement",
        sprite: ["2", 9, 3],
        tier: "boost",
    },
    {
        type: "spectrophotometer",
        category: "Measurement",
        sprite: ["2", 8, 4],
        tier: "boost",
    },
    {
        type: "spectrophotometer-s",
        category: "Measurement",
        sprite: ["2", 8, 5],
        tier: "boost",
    },
    {
        type: "spec20",
        category: "Measurement",
        sprite: ["3", 3, 3],
        tier: "boost",
    },
    {
        type: "pump",
        category: "_Mea_Extraction",
        sprite: [1, 2],
    },
    {
        type: "suction pear",
        category: "_Mea_Extraction",
        sprite: ["2", 5, 8],
    },
    {
        type: "dropper pipette",
        category: "_Mea_Extraction",
        sprite: [1, 6],
    },
    {
        type: "pipette bottle",
        category: "_Mea_Extraction",
        sprite: [7, 5],
    },
    {
        type: "plastic pipette",
        category: "_Mea_Extraction",
        sprite: [3, 6],
    },
    {
        type: "micropipette",
        category: "_Mea_Extraction",
        sprite: ["2", 2, 10],
        tier: "boost",
    },
    {
        type: "microplate",
        category: "_Mea_Extraction",
        sprite: ["2", 5, 7],
        tier: "boost",
    },
    {
        type: "gas syringe",
        category: "_Mea_Extraction",
        sprite: [1, 8],
    },
    {
        type: "syringe",
        category: "_Mea_Extraction",
        sprite: ["2", 2, 7],
    },
    {
        type: "needle",
        category: "_Mea_Extraction",
        sprite: ["3", 2, 6],
    },
    {
        type: "bunsen",
        category: "Heating",
        sprite: [2, 0],
    },
    {
        type: "torch burner",
        category: "Heating",
        sprite: ["2", 6, 7],
        tier: "boost",
    },
    {
        type: "flame",
        category: "Heating",
        sprite: [7, 10],
    },
    {
        type: "hot plate",
        category: "Heating",
        sprite: [2, 1],
    },
    {
        type: "magnetic stirrer-heater",
        category: "Heating",
        sprite: [2, 2],
    },
    {
        type: "spirit lamp",
        category: "Heating",
        sprite: [7, 9],
        tier: "boost",
    },
    {
        type: "stick lighter",
        category: "Heating",
        sprite: ["2", 6, 6],
        tier: "boost",
    },
    {
        type: "sand bath",
        category: "Heating",
        sprite: [5, 8],
    },
    {
        type: "heating mantle",
        category: "Heating",
        sprite: [8, 2],
    },
    {
        type: "soft mantle",
        category: "Heating",
        sprite: ["3", 3, 0],
        tier: "boost",
    },
    {
        type: "oven",
        category: "Heating",
        sprite: ["3", 3, 6],
    },
    {
        type: "kettle",
        category: "Heating",
        sprite: ["3", 4, 6],
    },
    {
        type: "stirring rod",
        category: "Heating",
        sprite: [2, 8],
    },
    {
        type: "magnetic stir bar",
        category: "Heating",
        sprite: [2, 3],
    },
    {
        type: "stirbar2",
        category: "Heating",
        sprite: ["3", 5, 6],
    },
    {
        type: "glass wool",
        category: "Heating",
        sprite: [2, 4],
    },
    {
        type: "wire gauze",
        category: "Heating",
        sprite: [2, 5],
    },
    {
        type: "clay triangle",
        category: "Heating",
        sprite: [2, 6],
    },
    {
        type: "tongs",
        category: "Heating",
        sprite: [2, 7],
    },
    {
        type: "peg",
        category: "Heating",
        sprite: [8, 3],
    },
    {
        type: "test tube holder",
        category: "Heating",
        sprite: [8, 4],
    },
    {
        type: "filter funnel",
        category: "Filtering",
        sprite: [3, 0],
    },
    {
        type: "thistle tube",
        category: "Filtering",
        sprite: [3, 1],
    },
    {
        type: "buchner",
        category: "Filtering",
        sprite: [3, 2],
        tier: "boost",
    },
    {
        type: "separatory funnel",
        category: "Filtering",
        sprite: [3, 3],
    },
    {
        type: "filter paper",
        category: "Filtering",
        sprite: [3, 4],
    },
    {
        type: "fluted filter paper",
        category: "Filtering",
        sprite: [9, 10],
        tier: "boost",
    },
    {
        type: "evaporating dish",
        category: "Filtering",
        sprite: [3, 5],
    },
    {
        type: "syringe filter",
        category: "Filtering",
        sprite: ["3", 5, 10],
    },
    {
        type: "spe cartridge",
        category: "Filtering",
        sprite: ["3", 6, 0],
    },
    {
        type: "allihn",
        category: "Condensers",
        sprite: [4, 2],
    },
    {
        type: "fractionating column",
        category: "Condensers",
        sprite: [4, 0],
    },
    {
        type: "graham",
        category: "Condensers",
        sprite: [4, 3],
    },
    {
        type: "liebig condenser",
        category: "Condensers",
        sprite: [4, 1],
    },
    {
        type: "dimroth",
        category: "Condensers",
        sprite: ["3", 2, 8],
        tier: "boost",
    },
    {
        type: "vigreux",
        category: "Condensers",
        sprite: ["2", 5, 3],
        tier: "boost",
    },
    {
        type: "waterless condenser",
        category: "Condensers",
        sprite: [7, 7],
        tier: "boost",
    },
    {
        type: "cold finger",
        category: "Distillation",
        sprite: [4, 6],
    },
    {
        type: "distillation head",
        category: "Distillation",
        sprite: [8, 9],
    },
    {
        type: "claisen",
        category: "Distillation",
        sprite: ["3", 5, 4],
    },
    {
        type: "splash head",
        category: "Distillation",
        sprite: ["2", 2, 5],
        tier: "boost",
    },
    {
        type: "soxhlet extractor",
        category: "Distillation",
        sprite: ["2", 0, 7],
        tier: "boost",
    },
    {
        type: "receiver adaptor",
        category: "Distillation",
        sprite: ["2", 0, 3],
        tier: "boost",
    },
    {
        type: "cowtype",
        category: "Distillation",
        sprite: ["2", 5, 5],
        tier: "boost",
    },
    {
        type: "dean-stark trap",
        category: "Distillation",
        sprite: ["2", 0, 5],
    },
    {
        type: "distillation flask",
        category: "Distillation",
        sprite: ["2", 0, 1],
    },
    {
        type: "oil bubbler",
        category: "Distillation",
        sprite: ["2", 5, 4],
        tier: "boost",
    },
    {
        type: "rubber tube",
        category: "Tubes",
        sprite: ["3", 4, 10],
    },
    {
        type: "capillary tube",
        category: "Tubes",
        sprite: [2, 10],
    },
    {
        type: "tube",
        category: "Tubes",
        sprite: [5, 0],
    },
    {
        type: "tube bend",
        category: "Tubes",
        sprite: [5, 1],
    },
    {
        type: "s-bent tube",
        category: "Tubes",
        sprite: [5, 4],
    },
    {
        type: "u-bent tube",
        category: "Tubes",
        sprite: [5, 6],
    },
    {
        type: "tube adapter",
        category: "Tubes",
        sprite: [5, 7],
    },
    {
        type: "3-way crossing",
        category: "Tubes",
        sprite: [5, 2],
    },
    {
        type: "4-way crossing",
        category: "Tubes",
        sprite: [5, 3],
        tier: "boost",
    },
    {
        type: "dropping funnel",
        category: "Tubes",
        sprite: [9, 8],
    },
    {
        type: "gas wash bottle",
        category: "Tubes",
        sprite: [5, 5],
        tier: "boost",
    },
    {
        type: "drying tube",
        category: "Tubes",
        sprite: ["3", 5, 1],
    },
    {
        type: "osmosis tube",
        category: "Tubes",
        sprite: ["2", 7, 0],
        tier: "boost",
    },
    {
        type: "dialysis tube",
        category: "Tubes",
        sprite: ["2", 6, 10],
        tier: "boost",
    },
    {
        type: "venturi tube",
        category: "Tubes",
        sprite: ["3", 4, 3],
        tier: "boost",
    },
    {
        type: "test tube rack",
        category: "Metallic",
        sprite: [6, 0],
    },
    {
        type: "retort stand",
        category: "Metallic",
        sprite: [6, 1],
    },
    {
        type: "clamp",
        category: "Metallic",
        sprite: [6, 2],
    },
    {
        type: "clamp front",
        category: "Metallic",
        sprite: ["2", 7, 6],
    },
    {
        type: "retort ring",
        category: "Metallic",
        sprite: [6, 5],
    },
    {
        type: "burette holder",
        category: "Metallic",
        sprite: [7, 8],
        tier: "boost",
    },
    {
        type: "burette clamp",
        category: "Metallic",
        sprite: ["2", 6, 1],
        tier: "boost",
    },
    {
        type: "spatula",
        category: "Metallic",
        sprite: [6, 3],
    },
    {
        type: "tripod",
        category: "Metallic",
        sprite: [6, 4],
    },
    {
        type: "nichrome loop",
        category: "Metallic",
        sprite: ["2", 2, 0],
        tier: "boost",
    },
    {
        type: "combustion spoon",
        category: "Metallic",
        sprite: ["2", 8, 10],
        tier: "boost",
    },
    {
        type: "steel wool",
        category: "Metallic",
        sprite: ["2", 5, 9],
    },
    {
        type: "hoffman clamp",
        category: "Metallic",
        sprite: ["2", 5, 10],
        tier: "boost",
    },
    {
        type: "mortar",
        category: "Ceramic",
        sprite: [7, 0],
    },
    {
        type: "pestle",
        category: "Ceramic",
        sprite: [7, 1],
    },
    {
        type: "beehive stand",
        category: "Ceramic",
        sprite: [7, 2],
        tier: "boost",
    },
    {
        type: "spotting tile",
        category: "Ceramic",
        sprite: [1, 10],
    },
    {
        type: "bench mat",
        category: "Ceramic",
        sprite: [3, 7],
    },
    {
        type: "crucible",
        category: "Ceramic",
        sprite: [7, 3],
    },
    {
        type: "crucible cover",
        category: "Ceramic",
        sprite: [7, 4],
    },
    {
        type: "fume hood",
        category: "Devices",
        sprite: ["2", 8, 0],
    },
    {
        type: "rotavap",
        category: "Devices",
        sprite: ["2", 0, 9],
        tier: "boost",
    },
    {
        type: "vacuum pump",
        category: "Devices",
        sprite: ["3", 4, 9],
    },
    {
        type: "centrifuge",
        category: "Devices",
        sprite: ["2", 9, 7],
        tier: "boost",
    },
    {
        type: "piston chamber",
        category: "Devices",
        sprite: ["2", 9, 10],
    },
    {
        type: "sonicator",
        category: "Devices",
        sprite: ["2", 7, 5],
        tier: "boost",
    },
    {
        type: "lab jack",
        category: "Devices",
        sprite: [8, 7],
        tier: "boost",
    },
    {
        type: "drop counter",
        category: "Devices",
        sprite: ["2", 7, 10],
        tier: "boost",
    },
    {
        type: "hofmann",
        category: "Devices",
        sprite: ["2", 2, 2],
        tier: "boost",
    },
    {
        type: "schlenk lines",
        category: "Devices",
        sprite: ["2", 0, 10],
        tier: "boost",
    },
    {
        type: "tlc plate",
        category: "Chromatography",
        sprite: ["2", 2, 3],
    },
    {
        type: "tlc spot",
        category: "Chromatography",
        sprite: ["2", 2, 4],
    },
    {
        type: "tlc chamber",
        category: "Chromatography",
        sprite: ["2", 9, 8],
    },
    {
        type: "reagent bottle",
        category: "Storage",
        sprite: [8, 0],
    },
    {
        type: "gas cylinder",
        category: "Storage",
        sprite: [8, 8],
    },
    {
        type: "plastic reagent bottle",
        category: "Storage",
        sprite: ["2", 0, 8],
        tier: "boost",
    },
    {
        type: "reagent2",
        category: "Storage",
        sprite: ["3", 3, 4],
        tier: "boost",
    },
    {
        type: "glass lab bottle",
        category: "Storage",
        sprite: ["3", 3, 7],
        tier: "boost",
    },
    {
        type: "desiccator",
        category: "Storage",
        sprite: [8, 1],
    },
    /** Substances */
    {
        type: "bubbles",
        category: "Substances",
        sprite: [4, 8],
    },
    {
        type: "crystals",
        category: "Substances",
        sprite: [4, 9],
    },
    {
        type: "beads",
        category: "Substances",
        sprite: ["2", 5, 0],
    },
    {
        type: "drop",
        category: "Substances",
        sprite: [9, 4],
    },
    {
        type: "particles",
        category: "Substances",
        sprite: ["3", 5, 3],
    },
    {
        type: "ice cube",
        category: "Substances",
        sprite: [4, 10],
    },
    {
        type: "metal strip",
        category: "Substances",
        sprite: [5, 9],
    },
    {
        type: "nail",
        category: "Substances",
        sprite: [9, 1],
    },
    {
        type: "powder",
        category: "Substances",
        sprite: [5, 10],
    },
    {
        type: "smoke",
        category: "Substances",
        sprite: ["2", 9, 2],
    },
    {
        type: "faucet",
        category: "Substances",
        sprite: ["2", 7, 2],
    },
    {
        type: "gummy bear",
        category: "Substances",
        sprite: ["3", 2, 0],
        tier: "boost",
    },
    {
        type: "capsule pill",
        category: "Medical",
        sprite: ["3", 5, 5],
    },
    /** Accessories */
    {
        type: "table",
        category: "Accessories",
        sprite: [9, 2],
    },
    {
        type: "glass stopper",
        category: "Accessories",
        sprite: ["2", 0, 0],
    },
    {
        type: "septum seal",
        category: "Accessories",
        sprite: ["3", 2, 9],
    },
    {
        type: "desk lamp",
        category: "Accessories",
        sprite: ["2", 4, 9],
    },
    {
        type: "wooden splint",
        category: "Accessories",
        sprite: [3, 8],
    },
    {
        type: "match",
        category: "Accessories",
        sprite: ["3", 4, 1],
        tier: "boost",
    },
    {
        type: "pencil",
        category: "Accessories",
        sprite: ["2", 8, 3],
    },
    {
        type: "felt tip pen",
        category: "Accessories",
        sprite: ["2", 8, 2],
    },
    {
        type: "balloon",
        category: "Accessories",
        sprite: ["2", 6, 9],
    },
    {
        type: "adhesive tape",
        category: "Accessories",
        sprite: ["2", 7, 4],
    },
    {
        type: "timer",
        category: "Accessories",
        sprite: [9, 6],
    },
    {
        type: "glove",
        category: "Accessories",
        sprite: [3, 9],
    },
    {
        type: "goggles",
        category: "Accessories",
        sprite: [3, 10],
    },
    {
        type: "lab coat",
        category: "Accessories",
        sprite: ["3", 4, 4],
    },
    {
        type: "magnifying glass",
        category: "Accessories",
        sprite: ["3", 1, 6],
    },
    {
        type: "bottle",
        category: "Accessories",
        sprite: ["2", 7, 7],
    },
    {
        type: "soda can",
        category: "Accessories",
        sprite: ["3", 4, 8],
    },
    {
        type: "candle",
        category: "Accessories",
        sprite: ["2", 8, 6],
        tier: "boost",
    },
    {
        type: "plastic joint clip",
        category: "Accessories",
        sprite: [9, 9],
    },
    {
        type: "cork ring",
        category: "Accessories",
        sprite: ["2", 2, 1],
        tier: "boost",
    },
    {
        type: "scissors",
        category: "Accessories",
        sprite: ["2", 2, 8],
    },
    {
        type: "corkborer",
        category: "Accessories",
        sprite: ["3", 6, 9],
    },
    {
        type: "paperclip",
        category: "Accessories",
        sprite: ["2", 9, 5],
    },
    {
        type: "tap",
        category: "Accessories",
        sprite: ["2", 2, 9],
        tier: "boost",
    },
    {
        type: "computer",
        category: "Accessories",
        sprite: ["3", 3, 2],
    },
    {
        type: "camera",
        category: "Accessories",
        sprite: ["2", 6, 3],
    },
    {
        type: "videocamera",
        category: "Accessories",
        sprite: ["2", 6, 4],
    },
    {
        type: "label",
        category: "Labelling",
        sprite: [9, 7],
    },
    {
        type: "eye",
        category: "Labelling",
        sprite: [9, 3],
    },
    {
        type: "hand",
        category: "Labelling",
        sprite: ["3", 2, 10],
    },
    {
        type: "ghs hazard",
        category: "Labelling",
        sprite: [8, 10],
    },
    {
        type: "rectangle",
        category: "Basic shapes",
        sprite: ["2", 1, 0],
    },
    {
        type: "ellipse",
        category: "Basic shapes",
        sprite: ["2", 1, 1],
    },
    {
        type: "line",
        category: "Basic shapes",
        sprite: ["2", 1, 7],
    },
    {
        type: "curve",
        category: "Basic shapes",
        sprite: ["2", 1, 6],
    },
    {
        type: "triangle",
        category: "Basic shapes",
        sprite: ["2", 8, 1],
    },
    {
        type: "arrow",
        category: "Arrows",
        sprite: ["2", 1, 4],
    },
    {
        type: "curved arrow",
        category: "Arrows",
        sprite: ["2", 9, 0],
    },
    {
        type: "stir arrow",
        category: "Arrows",
        sprite: ["2", 9, 1],
    },
    {
        type: "cross",
        category: "Basic shapes",
        sprite: ["2", 1, 2],
    },
    {
        type: "star",
        category: "Basic shapes",
        sprite: ["2", 1, 8],
        tier: "boost",
    },
    {
        type: "tick",
        category: "Basic shapes",
        sprite: ["2", 1, 3],
        tier: "boost",
    },
    {
        type: "cuboid",
        category: "3D",
        sprite: ["2", 1, 9],
    },
    {
        type: "isocube",
        category: "3D",
        sprite: ["3", 5, 0],
    },
    {
        type: "3dcylinder",
        category: "3D",
        sprite: ["2", 1, 5],
    },
    {
        type: "sphere",
        category: "3D",
        sprite: ["2", 1, 10],
        tier: "boost",
    },
    {
        type: "leaf",
        category: "Organic",
        sprite: ["2", 3, 0],
    },
    {
        type: "plant shoot",
        category: "Organic",
        sprite: ["2", 3, 9],
    },
    {
        type: "shoot",
        category: "Organic",
        sprite: ["2", 4, 1],
    },
    {
        type: "pond weed",
        category: "Organic",
        sprite: ["2", 4, 0],
    },
    {
        type: "daisy",
        category: "Organic",
        sprite: ["3", 3, 8],
    },
    {
        type: "tree",
        category: "Organic",
        sprite: ["3", 3, 9],
    },
    {
        type: "root",
        category: "Organic",
        sprite: ["2", 5, 1],
        tier: "boost",
    },
    {
        type: "seed",
        category: "Organic",
        sprite: ["2", 3, 2],
    },
    {
        type: "potato",
        category: "Organic",
        sprite: ["2", 3, 1],
    },
    {
        type: "agar",
        category: "Organic",
        sprite: ["2", 3, 6],
    },
    {
        type: "bug",
        category: "Organic",
        sprite: ["2", 5, 2],
        tier: "boost",
    },
    {
        type: "snail",
        category: "Organic",
        sprite: ["3", 5, 8],
        tier: "boost",
    },
    {
        type: "mouse",
        category: "Organic",
        sprite: ["3", 2, 7],
        tier: "boost",
    },
    {
        type: "bacteria",
        category: "Organic",
        sprite: ["2", 4, 4],
    },
    {
        type: "streaked bacteria",
        category: "Organic",
        sprite: ["2", 7, 3],
        tier: "boost",
    },
    {
        type: "microscope",
        category: "_Bio_Equipment",
        sprite: ["2", 3, 4],
    },
    {
        type: "microscope slide",
        category: "_Bio_Equipment",
        sprite: ["2", 3, 7],
    },
    {
        type: "cover slip",
        category: "_Bio_Equipment",
        sprite: ["2", 3, 8],
    },
    {
        type: "potometer",
        category: "_Bio_Equipment",
        sprite: ["2", 4, 3],
        tier: "boost",
    },
    {
        type: "tweezers",
        category: "_Bio_Equipment",
        sprite: ["2", 4, 2],
    },
    {
        type: "scalpel",
        category: "_Bio_Equipment",
        sprite: ["2", 4, 6],
        tier: "boost",
    },
    {
        type: "quadrat",
        category: "_Bio_Equipment",
        sprite: ["2", 4, 7],
    },
    {
        type: "water bath",
        category: "_Bio_Equipment",
        sprite: ["2", 4, 10],
    },
    {
        type: "pot",
        category: "_Bio_Other",
        sprite: ["2", 3, 10],
    },
    {
        type: "dirt",
        category: "_Bio_Other",
        sprite: ["2", 4, 5],
    },
    {
        type: "cotton wool",
        category: "_Bio_Other",
        sprite: ["2", 3, 5],
    },
    {
        type: "sun",
        category: "_Bio_Other",
        sprite: ["2", 4, 8],
    },
    {
        type: "powerpack",
        category: "Electric & Magnetic",
        sprite: ["3", 0, 1],
    },
    {
        type: "battery",
        category: "Electric & Magnetic",
        sprite: ["2", 6, 2],
    },
    {
        type: "cable",
        category: "Electric & Magnetic",
        sprite: ["3", 0, 4],
    },
    {
        type: "croc clips",
        category: "Electric & Magnetic",
        sprite: ["2", 0, 6],
        tier: "boost",
    },
    {
        type: "digital ammeter",
        category: "Electric & Magnetic",
        sprite: ["3", 1, 3],
    },
    {
        type: "digital voltmeter",
        category: "Electric & Magnetic",
        sprite: ["3", 1, 2],
    },
    {
        type: "multimeter",
        category: "Electric & Magnetic",
        sprite: ["3", 1, 7],
    },
    {
        type: "resistor",
        category: "Electric & Magnetic",
        sprite: ["3", 1, 9],
    },
    {
        type: "rheostat",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 7],
        tier: boostAfter(2024, 11, 1),
    },
    {
        type: "lamp",
        category: "Electric & Magnetic",
        sprite: [9, 5],
    },
    {
        type: "bulb2",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 1],
        tier: "boost",
    },
    {
        type: "diode",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 8],
    },
    {
        type: "led",
        category: "Electric & Magnetic",
        sprite: ["3", 1, 10],
    },
    {
        type: "knife switch",
        category: "Electric & Magnetic",
        sprite: ["3", 1, 8],
        tier: "boost",
    },
    {
        type: "switch",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 5],
        tier: "boost",
    },
    {
        type: "motor",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 2],
        tier: "boost",
    },
    {
        type: "capacitor",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 3],
        // CONSIDER BOOST
    },
    {
        type: "capacitor2",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 4],
        tier: "boost",
    },
    {
        type: "buzzer",
        category: "Electric & Magnetic",
        sprite: ["3", 6, 6],
        tier: "boost",
    },
    {
        type: "magnet",
        category: "Electric & Magnetic",
        sprite: ["3", 0, 0],
    },
    {
        type: "solenoid",
        category: "Electric & Magnetic",
        sprite: ["3", 1, 5],
    },
    {
        type: "electrode",
        category: "Electric & Magnetic",
        sprite: ["3", 3, 10],
    },
    {
        type: "salt bridge",
        category: "Electric & Magnetic",
        sprite: ["3", 4, 0],
    },
    {
        type: "autotransformer",
        category: "Electric & Magnetic",
        sprite: ["3", 3, 1],
        tier: "boost",
    },
    {
        type: "ammeter-s",
        category: "Electrical symbols",
        sprite: ["3", 0, 2],
        released: Flags.isElectricalSymbolsEnabled,
    },
    {
        type: "voltmeter-s",
        category: "Electrical symbols",
        sprite: ["3", 0, 3],
        released: Flags.isElectricalSymbolsEnabled,
    },
    {
        type: "raybox",
        category: "Optics",
        sprite: ["3", 0, 6],
    },
    {
        type: "ray",
        category: "Optics",
        sprite: ["3", 2, 1],
    },
    {
        type: "lens",
        category: "Optics",
        sprite: ["3", 2, 3],
    },
    {
        type: "glass block",
        category: "Optics",
        sprite: ["3", 0, 7],
    },
    {
        type: "mirror",
        category: "Optics",
        sprite: ["3", 0, 10],
    },
    {
        type: "curved mirror",
        category: "Optics",
        sprite: ["3", 2, 4],
    },
    {
        type: "prism",
        category: "Optics",
        sprite: ["3", 2, 5],
    },
    {
        type: "trolley",
        category: "Dynamics",
        sprite: ["3", 1, 0],
    },
    {
        type: "ramp",
        category: "Dynamics",
        sprite: ["3", 1, 1],
    },
    {
        type: "light gate",
        category: "Dynamics",
        sprite: ["3", 1, 4],
    },
    {
        type: "pulley",
        category: "Dynamics",
        sprite: ["3", 0, 5],
    },
    {
        type: "spring",
        category: "Dynamics",
        sprite: ["3", 0, 8],
    },
    {
        type: "slotted weight",
        category: "Dynamics",
        sprite: ["3", 0, 9],
    },
];
function boostAfter(year, month, day) {
    var now = new Date();
    var cutoff = new Date(year, month - 1, day);
    return (now > cutoff) ? "boost" : undefined;
}
var releasedItems = apparatusSpecList.filter(function (spec) { var _a; return ((_a = spec.released) !== null && _a !== void 0 ? _a : true); });
var releasedAndFreeItems = releasedItems.filter(function (spec) { return spec.tier === undefined; });
export var itemsThatRequireBoost = apparatusSpecList.filter(function (spec) { return spec.tier == "boost"; }).map(function (spec) { return spec.type; });
/** Count the number of items that are released. */
export var releasedItemsCount = releasedItems.length;
/** Count the number of items that are in free tier and publicly available. */
export var releasedAndFreeItemsCount = releasedAndFreeItems.length;
export default apparatusSpecList;
// Translation stuff
// const trans: any = {}
// for (const spec of apparatusSpecList) {
//   const properties = apparatusClass(spec.type).properties;
//   for (const spec of properties.appearanceSpecs) {
//     if (spec.spec === "header") continue;
//     trans[spec.label] = spec.label
//     if (spec.spec === "radio" || spec.spec === "select") {
//       for (const opt of spec.options) {
//         trans[opt.label] = opt.label
//       }
//     }
//     if (spec.spec === "color-wells") {
//       for (const opt of spec.colors) {
//         trans[opt.label] = opt.label
//       }
//     }
//     if (spec.spec === "checkbox") {
//       if (spec.subLabel) {
//         trans[spec.subLabel] = spec.subLabel
//       }
//     }
//     if (spec.spec === "toggle-buttons") {
//       for (const opt of spec.options) {
//         trans[opt.label] = opt.label
//       }
//     }
//   }
// }
// clog(trans);
