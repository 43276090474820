var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Gradients } from "apparatus/gradients";
import { Pivot, R, Segments, SharedGradients } from "apparatus/library/common";
import { Group, Path } from "paper";
import { staticImplements } from "types";
var CorkBorer = /** @class */ (function () {
    function CorkBorer() {
    }
    CorkBorer.prototype.render = function (_a) {
        var diameter = _a.diameter, length = _a.length;
        var handleColor = "#c03e1f".color();
        var handle = new Path(Segments([[10, -5], [0, -3]], [[30, -5], , [5, 0]]))
            .mirrorY()
            .mirrorX()
            .close();
        var handleOutline = handle.clone();
        var gradient = Gradients.scalePoints(Gradients.cylinder(handleColor), 0.4, 0.5);
        // Match the color of the last with the first
        gradient[gradient.length - 1][0] = gradient[0][0];
        handle.withGradientFill("right", gradient);
        handleOutline.withStroke("default", handleColor.darken(0.2).hex());
        var handleHighlight = new Path(Segments([[10, -5], [0, -3]], [[30, -5], , [5, 0]]))
            .mirrorY(-3.5)
            .mirrorX()
            .withFill(handleColor.lighten(0.5).alpha(0.6).string())
            .close();
        var borer = new Path.Rectangle(R(0, 0, diameter, length, Pivot.TOP_CENTER), 2.0)
            .withStroke("thin")
            .withGradientFill("right", SharedGradients.cylinderMetal);
        var graphic = new Group([borer, handle, handleHighlight, handleOutline]);
        return {
            graphic: graphic,
            hitShape: graphic.rectHitShape()
        };
    };
    CorkBorer.properties = {
        label: "Cork Borer",
        defaultAppearance: {
            diameter: 10,
            length: 80
        },
        appearanceSpecs: [
            {
                spec: "slider", key: "diameter", label: "Diameter",
                min: 4, max: 18
            },
        ],
    };
    CorkBorer = __decorate([
        staticImplements()
    ], CorkBorer);
    return CorkBorer;
}());
export { CorkBorer };
