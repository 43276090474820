var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { drawPivotAndCenter } from "apparatus/debug";
import { createRectangleDriver } from "apparatus/driver";
import { apparatusClass } from "apparatus/library";
import { lerp, P, Pp, S, Segments, SharedColors, UIColors } from "apparatus/library/common";
import { SVG } from "apparatus/svg";
import { graftInteraction, Interactable, SelectAndMove } from "editing/interaction";
import { MultiLiquid } from "editing/liquid/multilayer";
import { isLiquidLevelSufficientForDraining, isWithinDrainingAngles, isWithinPouringAngles, LIQUID_POURING_MIN_STREAM_WIDTH, MENISCUS_RADIUS_TO_HEIGHT } from "editing/liquid/surface";
import { transform } from "editing/snapping/type";
import { visualiseSnappings } from "editing/snapping/visualise";
import { clog } from "log";
import { Color, Gradient, Group, Path } from "paper";
import * as React from "react";
import { Debug } from "vars";
import { strokeWidth } from "./common";
var DEBUG_REDRAWS = false;
var LIQUID_LEVEL_OFFSET = 2.0;
var XRAY_DEFAULT_THICKNESS = 2.0;
/** Stroke width if a curve is used as a hit shape */
var CURVE_HIT_SHAPE_STROKE_WIDTH = 30;
/**
 * React component for placing and rendering an apparatus. Every
 * apparatus that is displayed on screen is wrapped by this component.
 * It handles properties common to all apparatus such as
 * rotation, hit targets, liquids etc.
 *
 * Rendering is delegated to a specific sublcass of Apparatus, based on
 * the type of apparatus.
 */
var ApparatusComponent = /** @class */ (function (_super) {
    __extends(ApparatusComponent, _super);
    function ApparatusComponent(props) {
        var _this = _super.call(this, props) || this;
        /** Interactable klass, to wrap hit shapes. */
        _this.hitShapeWrapperKlass = Interactable(paper.Group, SelectAndMove(_this));
        /**
         * The last zoom level for which the UI elements, such as interaction handles, were updated.
         */
        _this.lastUpdatedUiForZoom = -1;
        _this.id = props.id;
        _this.item = new paper.Group();
        _this.vizLayer = new paper.Group();
        _this.hitShapeLayer = new Group();
        _this.apparatusType = props.type;
        // Instantiate apparatus instance.
        var apparatusKlass = apparatusClass(_this.apparatusType);
        _this.apparatus = new apparatusKlass();
        // Add layers together.
        _this.item.addChild(_this.hitShapeLayer);
        // Set up initial state. Since this is the first time the
        // item is drawn, we also need to re-render the apparatus.
        _this.needsGraphicsRerender = true;
        _this.needsLiquidUpdate = true;
        _this.state = {
            position: { x: _this.props.x, y: _this.props.y },
            rotation: _this.props.rotation,
            liquid: _this.props.liquid,
            appearance: _this.props.appearance,
        };
        // Set up item.
        _this.item.applyMatrix = false;
        _this.vizLayer.applyMatrix = false;
        // Set up layer for storing drivers.
        var drivers = _this.apparatus.drivers;
        _this.setUpDriversLayer(drivers);
        if (DEBUG_REDRAWS)
            clog("Constructed: ", _this.item);
        return _this;
    }
    ApparatusComponent.prototype.UNSAFE_componentWillReceiveProps = function (nextProps) {
        // Reset state.
        // Note how redrawNeeded is computed: we want to redraw when
        // the apparatus appearance attributes have changed. We compare
        // the upcoming props with the current state (not props!) because
        // the currently drawn image is based on the state.
        // Note that we first compare if it's the exact same instance, then
        // we do deep equals using JSON.stringify() because I am lazy.
        // Comparison by "==" does not work: {a:1} == {a:1} is false.
        var sameAppearance = this.state.appearance === nextProps.appearance ||
            JSON.stringify(this.state.appearance) == JSON.stringify(nextProps.appearance);
        this.needsGraphicsRerender = !sameAppearance;
        var sameLiquid = sameAppearance &&
            (this.state.liquid === nextProps.liquid ||
                JSON.stringify(this.state.liquid) == JSON.stringify(nextProps.liquid));
        this.needsLiquidUpdate = !sameLiquid;
        // Update driver handles.
        this.driverHandles.forEach(function (h) { return h.onPropsChanged(nextProps.appearance); });
        this.setState({
            position: { x: nextProps.x, y: nextProps.y },
            rotation: nextProps.rotation,
            liquid: nextProps.liquid,
            appearance: nextProps.appearance,
        });
    };
    ApparatusComponent.prototype.componentWillUnmount = function () {
        var _a, _b;
        this.item.remove();
        this.vizLayer.remove();
        this.hitShapeLayer.remove();
        if (this.liquidLayer != null) {
            this.liquidLayer.remove();
        }
        (_a = this.liquidPourLayer) === null || _a === void 0 ? void 0 : _a.remove();
        (_b = this.liquidDrainLayer) === null || _b === void 0 ? void 0 : _b.remove();
    };
    ApparatusComponent.prototype.ensureSnappingLayer = function () {
        if (this.snappingLayer === undefined) {
            var snappingLayer = new paper.Group();
            this.vizLayer.addChild(snappingLayer);
            this.snappingLayer = snappingLayer;
        }
    };
    Object.defineProperty(ApparatusComponent.prototype, "currentPosition", {
        /** Returns the current position, based on app props. */
        get: function () {
            return new paper.Point(this.props.x, this.props.y);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "visiblePosition", {
        /** Returns actual position, based on drawn graphic. */
        get: function () {
            return new paper.Point(this.item.position.x, this.item.position.y);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "currentRotation", {
        /** Returns the current rotation, based on app state, in degrees. */
        get: function () {
            return this.props.rotation;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "rotation", {
        get: function () {
            return this.props.rotation;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "visibleRotation", {
        /** Returns the actual rotation, based on drawn graphic, in degrees. */
        get: function () {
            return this.item.rotation;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "bounds", {
        get: function () {
            return this.item.strokeBounds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "alignmentBounds", {
        get: function () {
            return this.bounds;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "isSelected", {
        get: function () {
            return this.props.selected;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ApparatusComponent.prototype, "parentGroup", {
        get: function () {
            return this.props.parentGroup;
        },
        enumerable: false,
        configurable: true
    });
    ApparatusComponent.prototype.forceCompleteRerender = function () {
        this.needsGraphicsRerender = true;
        this.needsLiquidUpdate = true;
    };
    ApparatusComponent.prototype.setPositionDelta = function (delta) {
        this.setState({
            position: {
                x: this.props.x + delta.x,
                y: this.props.y + delta.y,
            }
        });
    };
    ApparatusComponent.prototype.setRotationDelta = function (delta, pivot) {
        var position = Pp(this.props);
        var newPosition = position.rotate(delta, pivot);
        this.setState({
            position: newPosition,
            rotation: this.props.rotation + delta,
        });
    };
    /** Updates current apparatus appearance state. */
    ApparatusComponent.prototype.updateAppearanceAttributes = function (key, value) {
        // Make a copy of the current appearance state, overlay passed
        // attributes and then set it as the new state.
        // Appearance changes will always need a redraw.
        var newAppearance = __assign({}, this.state.appearance);
        newAppearance[key] = value;
        this.needsGraphicsRerender = true;
        this.needsLiquidUpdate = true;
        this.setState({
            appearance: newAppearance
        });
    };
    /** Updates current apparatus appearance state. */
    ApparatusComponent.prototype.updateMultipleAppearanceAttributes = function (partialAppearance) {
        var newAppearance = __assign(__assign({}, this.state.appearance), partialAppearance);
        this.needsGraphicsRerender = true;
        this.needsLiquidUpdate = true;
        this.setState({
            appearance: newAppearance
        });
    };
    /** Helper for updating liquid state */
    ApparatusComponent.prototype.updateLiquidData = function (liquidData) {
        this.needsLiquidUpdate = true;
        this.setState({
            liquid: liquidData
        });
    };
    Object.defineProperty(ApparatusComponent.prototype, "supportsLiquidPouring", {
        /** Returns true if the currently shown apparatus supports pouring. */
        get: function () {
            var _a;
            return ((_a = this.liquidLayer) === null || _a === void 0 ? void 0 : _a.children[2]) !== undefined;
        },
        enumerable: false,
        configurable: true
    });
    /** Converts a point in local coordinate to global. */
    ApparatusComponent.prototype.localToGlobal = function (p) {
        return p
            .subtract(this.item.pivot)
            .multiply([this.props.flipped ? -1 : 1, 1])
            .rotate(this.props.rotation)
            .add(this.item.position);
    };
    // Possibly do this only once!
    ApparatusComponent.prototype.recalculateGlobalSnapping = function () {
        var _this = this;
        if (this.snapping.length === 0) {
            return [];
        }
        return this.snapping.map(function (s) {
            return transform(s, _this.props.flipped, function (p) { return _this.localToGlobal(p); });
        });
    };
    ApparatusComponent.prototype.setUpSnappingVisualisationLayer = function () {
        var _a, _b, _c;
        var hasSnappingData = this.snapping.length > 0;
        if (hasSnappingData) {
            this.ensureSnappingLayer();
            (_a = this.snappingLayer) === null || _a === void 0 ? void 0 : _a.removeChildren();
        }
        else {
            // Remove layer containing the snap points.
            (_b = this.snappingLayer) === null || _b === void 0 ? void 0 : _b.removeChildren();
            (_c = this.snappingLayer) === null || _c === void 0 ? void 0 : _c.remove();
            this.snappingLayer = undefined;
        }
    };
    ApparatusComponent.prototype.setSnapVisualisation = function (filter) {
        this.setState({ visualiseSnapping: filter });
    };
    /** Note this is an expensive operation! */
    ApparatusComponent.prototype.setUpDriversLayer = function (drivers) {
        var _a;
        if (!drivers || drivers.length == 0) {
            // Clear all drivers.
            (_a = this.driversLayer) === null || _a === void 0 ? void 0 : _a.remove();
            this.driversLayer = undefined;
            this.driverHandles = [];
            return;
        }
        if (!this.driversLayer) {
            var driversLayer = new paper.Group();
            this.vizLayer.addChild(driversLayer);
            this.driverHandles = [];
            this.driversLayer = driversLayer;
        }
        for (var _i = 0, drivers_1 = drivers; _i < drivers_1.length; _i++) {
            var driver = drivers_1[_i];
            switch (driver.type) {
                case "rectangle":
                    var driverHandle = createRectangleDriver(driver, this);
                    driverHandle.onPropsChanged(this.props.appearance);
                    this.driverHandles.push(driverHandle);
                    this.driversLayer.addChild(driverHandle.handle);
            }
        }
    };
    ApparatusComponent.prototype.render = function () {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var watermarkPosition;
        // Render apparatus.
        if (this.needsGraphicsRerender) {
            if (DEBUG_REDRAWS)
                clog("Redraw: ", this.item);
            this.needsGraphicsRerender = false;
            var _k = this.apparatus.render(this.state.appearance), graphic = _k.graphic, pivot = _k.pivot, xray = _k.xray, hitShape = _k.hitShape, liquidMask = _k.liquidMask, snapping = _k.snapping, watermark = _k.watermark, liquidMaskOpening = _k.liquidMaskOpening, localLiquidDrainOpening = _k.liquidDrainOpening, ignoreRotation = _k.ignoreRotation, ignoreFlipping = _k.ignoreFlipping, anchors = _k.anchors;
            // Watermark position is used later.
            watermarkPosition = watermark;
            this.liquidDrainOpening = localLiquidDrainOpening;
            this.anchors = anchors;
            if (this.graphicLayer != null) {
                this.graphicLayer.replaceWith(graphic);
            }
            else {
                this.item.insertChild(0, graphic);
            }
            this.graphicLayer = graphic;
            // Set up Pivot if specified, otherwise take center.
            var mainPivot = pivot ? pivot : graphic.bounds.center;
            this.item.pivot = mainPivot;
            this.vizLayer.pivot = this.item.pivot;
            drawPivotAndCenter(this.item, "orange");
            // Update Xray.
            if (xray) {
                if (!this.xrayLayer) {
                    this.xrayLayer = new Group();
                    this.vizLayer.insertChild(0, this.xrayLayer);
                }
                this.xrayLayer.removeChildren();
                this.xrayLayer.addChild(xray);
                this.xrayLayer.withStroke(XRAY_DEFAULT_THICKNESS / this.props.zoom, UIColors.hintFill);
            }
            else {
                (_a = this.xrayLayer) === null || _a === void 0 ? void 0 : _a.remove();
                this.xrayLayer = undefined;
            }
            // Update Hit shape.
            this.hitShapeLayer.removeChildren();
            this.isHitShapeLayerStrokeBased = false;
            if (hitShape instanceof paper.Shape || hitShape instanceof paper.Path) {
                if (hitShape instanceof paper.Path && !hitShape.closed) {
                    hitShape.closePath();
                }
                this.hitShapeLayer.addChild(new (Interactable(paper.Group, SelectAndMove(this)))([hitShape]));
            }
            else if ("type" in hitShape && hitShape.type == "stroke") {
                this.isHitShapeLayerStrokeBased = true;
                graftInteraction(hitShape.path, SelectAndMove(this));
                hitShape.path.strokeWidth = CURVE_HIT_SHAPE_STROKE_WIDTH;
                hitShape.path.strokeCap = "round";
                this.hitShapeLayer.addChild(hitShape.path);
            }
            else {
                this.hitShapeLayer.addChildren(hitShape.map(function (h) { return new _this.hitShapeWrapperKlass([h]); }));
            }
            // Update liquid layer.
            if (liquidMask != null) {
                // Initialise the liquid layer.
                var liquidLayer = new paper.Group();
                liquidLayer.applyMatrix = false;
                liquidLayer.clipped = Debug.LIQUID_MASK ? false : true;
                // Insert layer into hierarchy.
                if (this.liquidLayer != null) {
                    this.liquidLayer.replaceWith(liquidLayer);
                }
                else {
                    this.item.insertChild(0, liquidLayer);
                    // Pre-insert the liquidPourLayer.
                    var liquidPourLayer = new Group();
                    this.item.insertChild(0, liquidPourLayer);
                    this.liquidPourLayer = liquidPourLayer;
                    // Pre-insert the liquidDrainLayer.
                    var liquidDrainLayer = new Group();
                    this.item.insertChild(0, liquidDrainLayer);
                    this.liquidDrainLayer = liquidDrainLayer;
                }
                this.liquidLayer = liquidLayer;
                // Add liquid mask.
                liquidLayer.addChild(liquidMask);
                liquidMask.applyMatrix = false;
                liquidMask.clipMask = Debug.LIQUID_MASK ? false : true;
                liquidMask.fillColor = "red";
                // Set up positions of items.
                liquidLayer.pivot = mainPivot;
                liquidMask.pivot = mainPivot;
                // Create liquid. The path is as follows:
                //     2   3
                //    ╭━━━━━╮
                // 0┌-┘1   4└-┐5
                //  |         |
                // 7└---------┘6
                var liquid = new paper.Path(Segments([[0, 0]], [[0, 0]], [[0, 0]], [[0, 0]], [[0, 0]], [[0, 0]], [[0, 0]], [[0, 0]]));
                liquid.fillColor = new paper.Color(0.5, 0.8, 0.9, 0.3);
                liquid.fullySelected = Debug.LIQUID_MASK;
                liquid.strokeWidth = strokeWidth("liquid");
                // WEIRD!!! If strokeScaling is disabled, then somehow gradients
                // also stop being relative to the item and are instead relative
                // to global coordinates! WTF?
                // liquid.strokeScaling = false
                liquid.applyMatrix = false;
                // liquid.position = mainPivot
                liquid.pivot = mainPivot;
                liquidLayer.addChild(liquid);
                drawPivotAndCenter(liquidLayer, "blue", 8.0);
                // Add the liquid mask opening. This should be invisible and is only
                // used for intersection calculation.
                if (liquidMaskOpening) {
                    var line = new Path.Line(liquidMaskOpening.start, liquidMaskOpening.end);
                    if (Debug.LIQUID_MASK)
                        line.withStroke(3, "blue");
                    liquidLayer.addChild(line); // Must be the second child.
                    line.applyMatrix = false;
                    line.pivot = mainPivot;
                }
            }
            else {
                // Remove the liquid layer.
                (_b = this.liquidLayer) === null || _b === void 0 ? void 0 : _b.removeChildren();
                (_c = this.liquidLayer) === null || _c === void 0 ? void 0 : _c.remove();
                this.liquidLayer = undefined;
                (_d = this.liquidPourLayer) === null || _d === void 0 ? void 0 : _d.remove();
                this.liquidPourLayer = undefined;
                (_e = this.liquidDrainLayer) === null || _e === void 0 ? void 0 : _e.remove();
                this.liquidDrainLayer = undefined;
            }
            // Snap points.
            this.snapping = snapping ? ((snapping instanceof Array) ? snapping : [snapping]) : [];
            // Create a layer containing snap points.
            this.setUpSnappingVisualisationLayer();
            // Update list of items that ignore rotation.
            if (ignoreRotation) {
                this.ignoreRotationItems = ignoreRotation;
                for (var _i = 0, ignoreRotation_1 = ignoreRotation; _i < ignoreRotation_1.length; _i++) {
                    var item = ignoreRotation_1[_i];
                    item.applyMatrix = false;
                }
            }
            else {
                this.ignoreRotationItems = [];
            }
            // Update list of items that ignore flipping.
            if (ignoreFlipping) {
                for (var _l = 0, ignoreFlipping_1 = ignoreFlipping; _l < ignoreFlipping_1.length; _l++) {
                    var item = ignoreFlipping_1[_l];
                    item.applyMatrix = false;
                }
                this.ignoreFlippingItems = ignoreFlipping;
            }
            else {
                this.ignoreFlippingItems = [];
            }
            // Set other flags.
            this.updateSnapVisualisationNeeded = true;
            // Update drivers.
            this.driverHandles.forEach(function (h) { return h.onStateChanged(_this.state.appearance); });
        }
        // Set position
        this.item.position.x = this.state.position.x;
        this.item.position.y = this.state.position.y;
        this.vizLayer.position.x = this.state.position.x;
        this.vizLayer.position.y = this.state.position.y;
        // Flip.
        this.item.scaling = P(this.props.flipped ? -1 : 1, 1);
        this.vizLayer.scaling = this.item.scaling;
        // Set rotation
        this.item.rotation = this.state.rotation;
        this.vizLayer.rotation = this.state.rotation;
        // Set opposite rotation to cancel out the rotation for items that should ignore rotation.
        for (var _m = 0, _o = this.ignoreRotationItems; _m < _o.length; _m++) {
            var item = _o[_m];
            item.rotation = -this.state.rotation;
        }
        for (var _p = 0, _q = this.ignoreFlippingItems; _p < _q.length; _p++) {
            var item = _q[_p];
            item.scaling = P(this.props.flipped ? -1 : 1, 1);
        }
        // Update position of snap points.
        this.globalSnapping = this.recalculateGlobalSnapping();
        // Change selection state.
        if (Debug.SHAPES) {
            this.graphicLayer.fullySelected = this.props.selected;
        }
        // Show hit shape if selected.
        if (this.isHitShapeLayerStrokeBased) {
            this.hitShapeLayer.strokeColor = SharedColors.selectionGreen + '80';
            if (this.props.selected && !Debug.PRESENTATION_MODE) {
                this.hitShapeLayer.opacity = 0.5;
            }
            else {
                this.hitShapeLayer.opacity = 0.000001;
            }
        }
        else {
            this.hitShapeLayer.fillColor = SharedColors.selectionGreen + '80';
            this.hitShapeLayer.strokeColor = SharedColors.selectionGreen;
            if (this.props.selected && !Debug.PRESENTATION_MODE) {
                this.hitShapeLayer.strokeWidth = 2.0;
                this.hitShapeLayer.opacity = 0.5;
                if (this.props.liquid) {
                    this.hitShapeLayer.fillColor = "#00000001";
                }
            }
            else {
                this.hitShapeLayer.strokeWidth = 0.0;
                this.hitShapeLayer.opacity = 0.000001;
            }
        }
        this.hitShapeLayer.blendMode = "normal";
        // Update drivers layer.
        if (this.driversLayer) {
            this.driversLayer.visible = this.props.selected && !(this.props.isInteracting == "zoom");
        }
        // Update xray layer.
        if (this.xrayLayer) {
            this.xrayLayer.visible = this.props.selected;
        }
        this.maybeUpdateUiElementsForZoom();
        // if (this.props.selected) {
        //     this.item.strokeColor = SharedColors.selectionGreen
        // } else {
        //     this.item.strokeColor = null
        // }
        // Update liquid.
        if (this.state.liquid && this.liquidLayer) {
            var liquidMaskItem = this.liquidLayer.children[0];
            var liquidShape = this.liquidLayer.children[1];
            var liquidMaskOpening = this.liquidLayer.children[2];
            var rotation = this.state.rotation * (this.props.flipped ? -1 : 1);
            rotation = rotation.anglify();
            var isRotationChanged = this.lastLiquidRotation != rotation;
            this.lastLiquidRotation = rotation;
            // Rotate the liquid layer back and then rotate the mask (and the mask opening line.)
            this.liquidLayer.rotation = -rotation;
            liquidMaskItem.rotation = rotation;
            if (liquidMaskOpening) {
                liquidMaskOpening.rotation = rotation;
            }
            if (this.needsLiquidUpdate || isRotationChanged) {
                this.needsLiquidUpdate = false;
                // Update amount.
                var liquidAmount = this.state.liquid.amountRatio;
                var levelLineY = lerp(liquidMaskItem.bounds.bottom, liquidMaskItem.bounds.top, liquidAmount) + LIQUID_LEVEL_OFFSET;
                if (liquidAmount > 0.001) {
                    var liquidBounds = liquidMaskItem.bounds;
                    // Calculate the height of the liquid.
                    var liquidHeight = levelLineY;
                    // Adjustments to hide the stroke on all sides except top.
                    // liquidShape.bounds.width += 4
                    // liquidShape.bounds.x -= 2
                    // liquidShape.bounds.height += 2
                    liquidShape.segments[0].point = P(liquidBounds.left - 2, liquidHeight);
                    liquidShape.segments[1].point = P(liquidBounds.left - 2, liquidHeight);
                    liquidShape.segments[1].handleOut.length = 0;
                    liquidShape.segments[2].point = P(liquidBounds.left - 2, liquidHeight);
                    liquidShape.segments[2].handleIn.length = 0;
                    liquidShape.segments[3].point = P(liquidBounds.right + 2, liquidHeight);
                    liquidShape.segments[3].handleOut.length = 0;
                    liquidShape.segments[4].point = P(liquidBounds.right + 2, liquidHeight);
                    liquidShape.segments[4].handleIn.length = 0;
                    liquidShape.segments[5].point = P(liquidBounds.right + 2, liquidHeight);
                    liquidShape.segments[6].point = liquidBounds.bottomRight.add(P(0, 1));
                    liquidShape.segments[7].point = liquidBounds.bottomLeft.add(P(0, 1));
                    // liquidShape.bounds.height = liquidHeight
                    // liquidShape.bounds.width = liquidBounds.width
                    // liquidShape.bounds.bottom = liquidBounds.bottom
                    // liquidShape.bounds.left = liquidBounds.left
                    liquidShape.visible = true;
                }
                else {
                    liquidShape.visible = false;
                }
                // Update color. This must be done after the liquid's
                // bounds have been updated, otherwise the colour "jumps".
                var liquidBodyColour = this.state.liquid.color.color().alpha(this.state.liquid.alpha).toString();
                var liquidStrokeColour = this.state.liquid.color;
                if (this.state.liquid.layers.length > 0) {
                    liquidShape.setGradientFill("up", MultiLiquid.toGradientStops(this.state.liquid.layers, liquidBodyColour));
                }
                else {
                    liquidShape.fillColor = liquidBodyColour;
                }
                liquidShape.strokeColor = liquidStrokeColour;
                // Pouring implementation.
                if (liquidMaskOpening && this.state.liquid.pouring.enabled && isWithinPouringAngles(rotation)) {
                    // Calculate interesection of the liquid with the liquid mask. This is done by creating a level line
                    // in item space. This doesn't need to be rotated and can then be intersected with the liquid mask top line.
                    var levelLine = new Path.Line(P(liquidMaskItem.bounds.left, levelLineY), P(liquidMaskItem.bounds.right, levelLineY));
                    var intersections = liquidMaskOpening.getIntersections(levelLine);
                    levelLine.remove();
                    this.liquidPourLayer.removeChildren();
                    if (intersections.length > 0) {
                        var intersection = intersections[0];
                        // Find the bottom lip point.
                        var bottomLip = (rotation > 0)
                            ? liquidMaskOpening.segments[1].point
                            : liquidMaskOpening.segments[0].point;
                        var absRotation = Math.abs(rotation);
                        var flowLength = this.state.liquid.pouring.flowLength;
                        var outflowSize = Math.sin(absRotation.toRadians()) * bottomLip.getDistance(intersection.point);
                        var flowWidth = Math.max(this.state.liquid.pouring.flowStrength * 1.1 * outflowSize, LIQUID_POURING_MIN_STREAM_WIDTH);
                        if (rotation < 0)
                            flowWidth *= -1;
                        var liquidPath = new Path([
                            S(bottomLip),
                            // Use the bottomLip as the pivot to calculate the endpoints of
                            // the stream.
                            S(bottomLip.add([0, flowLength]).rotate(-rotation, bottomLip)),
                            S(bottomLip.add([flowWidth, flowLength]).rotate(-rotation, bottomLip)),
                            S(bottomLip.add([flowWidth, 0]).rotate(-rotation, bottomLip), undefined, P(0, -Math.abs(outflowSize) * 0.75).rotate(-rotation)),
                            S(intersection.point, P((Math.abs(flowWidth) + Math.cos(rotation.toRadians()) * bottomLip.getDistance(intersection.point)) * 0.75, 0).rotate(-rotation + (rotation < 0 ? 180 : 0))),
                        ]);
                        liquidPath.fullySelected = Debug.LIQUID_MASK;
                        var outline = new Group([
                            new Path(liquidPath.segments.slice(0, 2)),
                            new Path(liquidPath.segments.slice(2, 5)),
                        ]);
                        if (this.state.liquid.pouring.fade) {
                            liquidPath.fillColor = new Color(new Gradient([
                                [liquidBodyColour.color().alpha(0).string(), 0.0],
                                // Fade out in about 15 points / 1.5cm.
                                [liquidBodyColour, Math.min(0.5, 15 / flowLength)],
                            ]), liquidPath.segments[1].point, liquidPath.segments[0].point);
                            outline.strokeWidth = strokeWidth("liquid");
                            outline.strokeCap = "round";
                            outline.strokeColor = new Color(new Gradient([
                                [liquidStrokeColour.color().alpha(0).string(), Math.min(0.4, 5 / flowLength)],
                                // Fade out for stroke is slightly larger due to their opacity.
                                [liquidStrokeColour, Math.min(0.6, 20 / flowLength)],
                            ]), liquidPath.segments[1].point, liquidPath.segments[0].point);
                        }
                        else {
                            // No fade effect.
                            liquidPath.withFill(liquidBodyColour);
                            outline.withStroke(strokeWidth("liquid"), liquidStrokeColour);
                        }
                        (_f = this.liquidPourLayer) === null || _f === void 0 ? void 0 : _f.addChildren([liquidPath, outline]);
                    }
                }
                else {
                    (_g = this.liquidPourLayer) === null || _g === void 0 ? void 0 : _g.removeChildren();
                }
                // Draining implementation.
                if (this.liquidDrainOpening && this.state.liquid.draining.enabled
                    && isWithinDrainingAngles(rotation)
                    && isLiquidLevelSufficientForDraining(this.state.liquid)) {
                    this.liquidDrainLayer.removeChildren();
                    var opening = this.liquidDrainOpening;
                    // Determine the height difference between left (start) and right (end).
                    var adjustedEnd = opening.end.rotate(rotation, opening.start);
                    var diffY = adjustedEnd.y - opening.start.y;
                    // Calculate flow lengths.
                    var flowLength = this.state.liquid.draining.flowLength;
                    var leftFlowLength = flowLength + (diffY > 0 ? diffY : 0);
                    var rightFlowLength = flowLength - (diffY < 0 ? diffY : 0);
                    // Calculate fluid column thinning.
                    var thinning = 0;
                    if (this.state.liquid.draining.thinning) {
                        var flowWidth = adjustedEnd.x - opening.start.x;
                        // Thinning shouldn't reduce the column width below 4 pixels wide, if the flow
                        // is above 4 pixels wide.
                        thinning = flowWidth >= 4 ? (flowWidth - Math.max(4, flowWidth * 0.5)) / 2 : 0;
                    }
                    var liquidPath = new Path([
                        S(opening.start, undefined, P(thinning, 0).rotate(-rotation)),
                        S(opening.start.add(P(thinning, leftFlowLength).rotate(-rotation)), P(0, -leftFlowLength * 0.85).rotate(-rotation)),
                        S(opening.end.add(P(-thinning, rightFlowLength).rotate(-rotation)), undefined, P(0, -rightFlowLength * 0.85).rotate(-rotation)),
                        S(opening.end, P(-thinning, 0).rotate(-rotation)),
                    ]);
                    var outline = new Group([
                        new Path(liquidPath.segments.slice(0, 2)),
                        new Path(liquidPath.segments.slice(2, 4)),
                    ]);
                    // Set colour.
                    var hasLayers = this.state.liquid.layers.length > 0;
                    var stroke = hasLayers
                        ? this.state.liquid.layers[0].color
                        : liquidStrokeColour;
                    var fill = hasLayers
                        ? stroke.color().alpha(this.state.liquid.layers[0].alpha).string()
                        : liquidBodyColour;
                    outline.strokeWidth = strokeWidth("liquid");
                    outline.strokeCap = "round";
                    if (this.state.liquid.draining.fade) {
                        liquidPath.fillColor = new Color(new Gradient([
                            [fill.color().alpha(0).string(), 0.0],
                            // Fade out in about 15 points / 1.5cm.
                            [fill, Math.min(0.5, 15 / flowLength)],
                        ]), liquidPath.segments[1].point, liquidPath.segments[0].point);
                        outline.strokeColor = new Color(new Gradient([
                            [stroke.color().alpha(0).string(), Math.min(0.4, 5 / flowLength)],
                            // Fade out for stroke is slightly larger due to their opacity.
                            [stroke, Math.min(0.6, 20 / flowLength)],
                        ]), liquidPath.segments[1].point, liquidPath.segments[0].point);
                    }
                    else {
                        liquidPath.fillColor = fill;
                        outline.strokeColor = stroke;
                    }
                    this.liquidDrainLayer.addChildren([liquidPath, outline]);
                }
                else {
                    (_h = this.liquidDrainLayer) === null || _h === void 0 ? void 0 : _h.removeChildren();
                }
                // Meniscus implementation.
                if (this.state.liquid.meniscus.enabled && rotation == 0) {
                    var meniscusRadius = this.state.liquid.meniscus.radius;
                    var meniscusHeight = meniscusRadius * MENISCUS_RADIUS_TO_HEIGHT;
                    var convex = this.state.liquid.meniscus.convex;
                    // Adjust the meniscusHeight based on how much room is left.
                    if (convex) {
                        meniscusHeight = Math.min(meniscusHeight, liquidMaskItem.bounds.bottom - levelLineY - LIQUID_LEVEL_OFFSET);
                    }
                    else {
                        meniscusHeight = Math.min(meniscusHeight, levelLineY - liquidMaskItem.bounds.top - LIQUID_LEVEL_OFFSET);
                    }
                    // Readjust radius
                    meniscusRadius = meniscusHeight / MENISCUS_RADIUS_TO_HEIGHT;
                    // Create a level line with adjusted level line.
                    var meniscusYOffset = convex ? meniscusHeight : -meniscusHeight;
                    var levelLine = new Path.Line(P(liquidMaskItem.bounds.left, levelLineY + meniscusYOffset), P(liquidMaskItem.bounds.right, levelLineY + meniscusYOffset));
                    var intersections = liquidMaskItem.getIntersections(levelLine);
                    levelLine.remove();
                    if (intersections.length == 2) {
                        var swap = intersections[1].point.x < intersections[0].point.x;
                        var leftPoint = swap ? intersections[1].point : intersections[0].point;
                        var rightPoint = swap ? intersections[0].point : intersections[1].point;
                        // Shift the points by the stroke thickness. This isn't bulletproof
                        // and does not do enough shifting on slanting glassware but should
                        // look good enough.
                        var halfStrokeWidth = strokeWidth("default") / 2;
                        leftPoint.x += halfStrokeWidth;
                        rightPoint.x -= halfStrokeWidth;
                        // Readjust offsets based on horizontal space available.
                        meniscusRadius = Math.min(meniscusRadius, Math.abs(intersections[0].point.x - intersections[1].point.x) / 2);
                        meniscusYOffset = (convex ? meniscusRadius : -meniscusRadius) * MENISCUS_RADIUS_TO_HEIGHT;
                        liquidShape.segments[1].point = leftPoint;
                        // liquidShape.segments[1].handleOut = P(0, -meniscusYOffset * 0.66)
                        liquidShape.segments[2].point = leftPoint.add([meniscusRadius, -meniscusYOffset]);
                        liquidShape.segments[2].handleIn = P(-meniscusRadius * 0.72, 0);
                        liquidShape.segments[3].point = rightPoint.add([-meniscusRadius, -meniscusYOffset]);
                        liquidShape.segments[3].handleOut = P(meniscusRadius * 0.72, 0);
                        liquidShape.segments[4].point = rightPoint;
                        // liquidShape.segments[4].handleIn = P(0, -meniscusYOffset * 0.66)
                    }
                } // else: when the liquid is first adjusted, it should alredy be level.
            }
        }
        // Watermarking.
        if (this.props.isWatermarkEnabled) {
            this.graphicLayer.opacity = 0.5;
            if (!this.watermarkLayer) {
                this.watermarkLayer = SVG.boostBadge();
                this.vizLayer.addChild(this.watermarkLayer);
            }
            if (watermarkPosition) {
                this.watermarkLayer.position = watermarkPosition;
            }
        }
        else {
            this.graphicLayer.opacity = 1.0;
            (_j = this.watermarkLayer) === null || _j === void 0 ? void 0 : _j.remove();
            this.watermarkLayer = undefined;
        }
        // Hit shape debugging.
        if (Debug.HIT_SHAPES) {
            this.hitShapeLayer.fillColor = "red";
            this.hitShapeLayer.opacity = 0.8;
        }
        return null;
    };
    ApparatusComponent.prototype.componentDidUpdate = function (prevProps, prevState) {
        if (prevState.visualiseSnapping != this.state.visualiseSnapping) {
            this.updateSnapVisualisationNeeded = true;
        }
        // Display snap points.
        this.maybeRenderSnappings();
    };
    ApparatusComponent.prototype.maybeRenderSnappings = function () {
        if (!this.snappingLayer)
            return;
        if (!this.updateSnapVisualisationNeeded)
            return;
        this.updateSnapVisualisationNeeded = false;
        this.snappingLayer.removeChildren();
        if (this.state.visualiseSnapping) {
            visualiseSnappings(this.snappingLayer, this.snapping, this.state.visualiseSnapping);
        }
    };
    ApparatusComponent.prototype.maybeUpdateUiElementsForZoom = function () {
        if (this.props.zoom != this.lastUpdatedUiForZoom) {
            var zoom_1 = this.props.zoom;
            this.driverHandles.forEach(function (h) { return h.handle.scaling = P(1 / zoom_1, 1 / zoom_1); });
            this.lastUpdatedUiForZoom = zoom_1;
            if (this.xrayLayer) {
                this.xrayLayer.strokeWidth = XRAY_DEFAULT_THICKNESS / zoom_1;
            }
        }
    };
    return ApparatusComponent;
}(React.Component));
export { ApparatusComponent };
