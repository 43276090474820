var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Balloon } from "apparatus/library/accessories/balloon";
import { Candle } from "apparatus/library/accessories/candle";
import { Computer } from "apparatus/library/accessories/computer";
import { CorkBorer } from "apparatus/library/accessories/cork_borer";
import { CorkRing } from "apparatus/library/accessories/cork_ring";
import { Goggles } from "apparatus/library/accessories/goggles";
import { GummyBear } from "apparatus/library/accessories/gummy_bear";
import { Hand } from "apparatus/library/accessories/hand";
import { LabCoat } from "apparatus/library/accessories/labcoat";
import { DeskLamp } from "apparatus/library/accessories/lamp";
import { LitmusPaper } from "apparatus/library/accessories/litmus_paper";
import { MagnifyingGlass } from "apparatus/library/accessories/magnifying_glass";
import { Match } from "apparatus/library/accessories/match";
import { PaperClip } from "apparatus/library/accessories/paper_clip";
import { Scissors } from "apparatus/library/accessories/scissors";
import { Sellotape } from "apparatus/library/accessories/sellotape";
import { SeptumSeal } from "apparatus/library/accessories/septum_seal";
import { Smoke } from "apparatus/library/accessories/smoke";
import { SodaCan } from "apparatus/library/accessories/soda_can";
import { Splint } from "apparatus/library/accessories/splint";
import { Tap } from "apparatus/library/accessories/tap";
import { TLCChamber } from "apparatus/library/accessories/tlc_chamber";
import { TLCPlate } from "apparatus/library/accessories/tlc_plate";
import { TLCSpot } from "apparatus/library/accessories/tlc_spot";
import { CottonWool } from "apparatus/library/accessories/wool";
import { Balance } from "apparatus/library/balance";
import { BenchMat } from "apparatus/library/bench_mat";
import { ChoiceChamber } from "apparatus/library/biology/equipment/choice_chamber";
import { CoverSlip } from "apparatus/library/biology/equipment/cover_slip";
import { Microplate } from "apparatus/library/biology/equipment/microplates";
import { MicroscopeSlide } from "apparatus/library/biology/equipment/microscope_slide";
import { PetriTop } from "apparatus/library/biology/equipment/petri_top";
import { Potometer } from "apparatus/library/biology/equipment/potometer";
import { Quadrat } from "apparatus/library/biology/equipment/quadrat";
import { Scalpel } from "apparatus/library/biology/equipment/scalpel";
import { Tweezers } from "apparatus/library/biology/equipment/tweezers";
import { WaterBath } from "apparatus/library/biology/equipment/water_bath";
import { Agar } from "apparatus/library/biology/organic/agar";
import { Bacteria } from "apparatus/library/biology/organic/bacteria";
import { Bug } from "apparatus/library/biology/organic/bug";
import { Daisy } from "apparatus/library/biology/organic/daisy";
import { Leaf } from "apparatus/library/biology/organic/leaf";
import { Mouse } from "apparatus/library/biology/organic/mouse";
import { PlantShoot } from "apparatus/library/biology/organic/plant_shoot";
import { PondWeed } from "apparatus/library/biology/organic/pond_weed";
import { Potato } from "apparatus/library/biology/organic/potato";
import { Root } from "apparatus/library/biology/organic/root";
import { Seed } from "apparatus/library/biology/organic/seed";
import { Shoot } from "apparatus/library/biology/organic/shoot";
import { Snail } from "apparatus/library/biology/organic/snail";
import { StreakedBacteria } from "apparatus/library/biology/organic/streaked_bacteria";
import { Tree } from "apparatus/library/biology/organic/tree";
import { Dirt } from "apparatus/library/biology/other/dirt";
import { PlantPot } from "apparatus/library/biology/other/plant_pot";
import { Sun } from "apparatus/library/biology/other/sun";
import { Borked } from "apparatus/library/borked";
import { Bung } from "apparatus/library/bung";
import { Burette } from "apparatus/library/burette";
import { CapillaryTube } from "apparatus/library/capillary_tube";
import { DEFAULT_LIQUID_DATA } from "apparatus/library/common";
import { AutosamplerVial } from "apparatus/library/container/autosampler_vial";
import { CellCultureFlask } from "apparatus/library/container/cell_flask";
import { CentrifugeTube } from "apparatus/library/container/centrifugeTube";
import { EppendorfTube } from "apparatus/library/container/eppendorfTube";
import { EurekaCan } from "apparatus/library/container/eureka_can";
import { HalfCellTube } from "apparatus/library/container/half_cell_tube";
import { IceBath } from "apparatus/library/container/ice_bath";
import { ThermosFlask } from "apparatus/library/container/thermos_flask";
import { WeightBoat } from "apparatus/library/container/weight_boat";
import { Crucible, CrucileCover } from "apparatus/library/crucible";
import { Cuvette } from "apparatus/library/cuvette";
import { Camera, VideoCamera } from "apparatus/library/decorative/camera";
import { Drop } from "apparatus/library/decorative/drop";
import { Eye } from "apparatus/library/decorative/eye";
import { Particles } from "apparatus/library/decorative/particles";
import { Desiccator } from "apparatus/library/desiccator";
import { Centrifuge } from "apparatus/library/devices/centrifuge";
import { Hofmann } from "apparatus/library/devices/hofmann";
import { PistonChamber } from "apparatus/library/devices/piston_chamber";
import { Rotavap } from "apparatus/library/devices/rotavap";
import { Spectrophotometer } from "apparatus/library/devices/spectrophotometer";
import { SpetrophotometerSchematic } from "apparatus/library/devices/spectrophotometer_schematic";
import { VacuumPump } from "apparatus/library/devices/vacuum_pump";
import { AllihnCondenser2 } from "apparatus/library/distillation/allihn";
import { ClaisenHead } from "apparatus/library/distillation/claisen_head";
import { CowType } from "apparatus/library/distillation/cow_type";
import { DeanStarTrap as DeanStarkTrap } from "apparatus/library/distillation/dean_stark_trap";
import { DimorthCondenser } from "apparatus/library/distillation/dimroth";
import { FractionatingColumn2 } from "apparatus/library/distillation/fractionating";
import { LiebigCondenser } from "apparatus/library/distillation/liebig";
import { OilBubbler } from "apparatus/library/distillation/oil_bubbler";
import { ReceiverAdaptor } from "apparatus/library/distillation/receiver_adaptor";
import { SoxhletExtractor } from "apparatus/library/distillation/soxhlet";
import { SplashHead } from "apparatus/library/distillation/splash_head";
import { Vigreux } from "apparatus/library/distillation/vigreux";
import { Dropper } from "apparatus/library/dropper";
import { EvaporatingDish } from "apparatus/library/evaporating_dish";
import { SPECartridge } from "apparatus/library/extraction/spe_cartridge";
import { FilterFunnel, FilterPaper, FlutedFilterPaper } from "apparatus/library/filter";
import { SyringeFilter } from "apparatus/library/filtering/syringe_filter";
import { GasJar } from "apparatus/library/gas_jar";
import { GasSyringe } from "apparatus/library/gas_syringe";
import { GasWashBottle } from "apparatus/library/gas_wash_bottle";
import { GlassWool } from "apparatus/library/glass_wool";
import { BoilingTube, BoilingTubeWithSideArm } from "apparatus/library/heating/boilingtube";
import { CombustionSpoon } from "apparatus/library/heating/combustion_spoon";
import { EllipticalStirringBar } from "apparatus/library/heating/elliptical_stir_bar";
import { Oven } from "apparatus/library/heating/oven";
import { Peg } from "apparatus/library/heating/peg";
import { StickLighter } from "apparatus/library/heating/stick_lighter";
import { TestTubeHolder } from "apparatus/library/heating/testTubeHolder";
import { TorchBurner } from "apparatus/library/heating/torch";
import { HotPlate } from "apparatus/library/hot_plate";
import { MagneticStirrer, StirBar } from "apparatus/library/magnetic_stirrer";
import { BeamBalance } from "apparatus/library/measurement/beam_balance";
import { DigitalAmmemter, DigitalVoltmeter } from "apparatus/library/measurement/digital_meters";
import { Dynamometer } from "apparatus/library/measurement/dynamometer";
import { Eudiometer } from "apparatus/library/measurement/eudiotmeter";
import { Hydrometer } from "apparatus/library/measurement/hydrometer";
import { Manometer } from "apparatus/library/measurement/manometer";
import { Micropipette } from "apparatus/library/measurement/micropipette";
import { OxSatProbe } from "apparatus/library/measurement/oxsat_probe";
import { Protractor } from "apparatus/library/measurement/protractor";
import { Pycnometer } from "apparatus/library/measurement/pycnometer";
import { Spectronic20 } from "apparatus/library/measurement/spec20";
import { SuctionPear } from "apparatus/library/measurement/suction_pear";
import { TapeMeasure } from "apparatus/library/measurement/tape";
import { TripleBeamBalance } from "apparatus/library/measurement/triple_beam_balance";
import { VernierCaliper } from "apparatus/library/measurement/vernier";
import { HoffmanClamp } from "apparatus/library/metallic/hoffman_clamp";
import { NichromeLoop } from "apparatus/library/metallic/nichrome_loops";
import { SteelWool } from "apparatus/library/metallic/steel_wool";
import { MetalStrip } from "apparatus/library/metalstrip";
import { Mortar, Pestle } from "apparatus/library/mortar";
import { Nail } from "apparatus/library/nail";
import { Pulley } from "apparatus/library/physics/dynamics/pulley";
import { Ramp } from "apparatus/library/physics/dynamics/ramp";
import { SlottedWeight } from "apparatus/library/physics/dynamics/slotted_weight";
import { Spring } from "apparatus/library/physics/dynamics/spring";
import { Trolley } from "apparatus/library/physics/dynamics/trolley";
import { SimpleAmmeter } from "apparatus/library/physics/electric/ammeter";
import { Autotransformer } from "apparatus/library/physics/electric/autotransformer";
import { Battery } from "apparatus/library/physics/electric/battery";
import { Cable } from "apparatus/library/physics/electric/cable";
import { Capacitor, MountedCapacitor } from "apparatus/library/physics/electric/capacitor";
import { CrocodileClips } from "apparatus/library/physics/electric/crocodile_clip";
import { Diode } from "apparatus/library/physics/electric/diode";
import { Electrode } from "apparatus/library/physics/electric/electrode";
import { Led } from "apparatus/library/physics/electric/led";
import { Magnet } from "apparatus/library/physics/electric/magnet";
import { MountedBulb } from "apparatus/library/physics/electric/mounted/bulb";
import { MountedBuzzer } from "apparatus/library/physics/electric/mounted/buzzer";
import { KnifeSwitch } from "apparatus/library/physics/electric/mounted/knife_switch";
import { Motor } from "apparatus/library/physics/electric/mounted/motor";
import { MountedSwitch } from "apparatus/library/physics/electric/mounted/switch";
import { Multimeter } from "apparatus/library/physics/electric/multimeter";
import { PowerPack } from "apparatus/library/physics/electric/powerpack";
import { Resistor } from "apparatus/library/physics/electric/resistor";
import { Rheostat } from "apparatus/library/physics/electric/rheostat";
import { SaltBridge } from "apparatus/library/physics/electric/salt_bridge";
import { Solenoid } from "apparatus/library/physics/electric/solenoid";
import { SimpleVoltmeter } from "apparatus/library/physics/electric/voltmeter";
import { GlassBlock } from "apparatus/library/physics/optical/glass_block";
import { Lens } from "apparatus/library/physics/optical/lenses";
import { LightGate } from "apparatus/library/physics/optical/light_gates";
import { CurvedMirror, Mirror } from "apparatus/library/physics/optical/mirror";
import { Prism } from "apparatus/library/physics/optical/prism";
import { Ray } from "apparatus/library/physics/optical/ray";
import { RayBox } from "apparatus/library/physics/optical/raybox";
import { MohrPipette, Pipette, PlasticPipette } from "apparatus/library/pipette";
import { Pump } from "apparatus/library/pump";
import { RasterImage } from "apparatus/library/raster_image";
import { ReagentBottle } from "apparatus/library/reagent_bottle";
import { RetortStand } from "apparatus/library/retort";
import { Ruler } from "apparatus/library/ruler";
import { SchlenkFlask } from "apparatus/library/schlenk_flask";
import { Arrow } from "apparatus/library/shapes/arrow";
import { Cross } from "apparatus/library/shapes/cross";
import { Curve, CurvedArrow, StirArrow } from "apparatus/library/shapes/curve";
import { Ellipse } from "apparatus/library/shapes/ellipse";
import { IsoCuboid } from "apparatus/library/shapes/iso_cuboid";
import { AngleLabel } from "apparatus/library/shapes/label/angle";
import { LengthLabel } from "apparatus/library/shapes/label/length";
import { Line } from "apparatus/library/shapes/line";
import { Rectangle } from "apparatus/library/shapes/rectangle";
import { Star } from "apparatus/library/shapes/star";
import { Tick } from "apparatus/library/shapes/tick";
import { Triangle } from "apparatus/library/shapes/triangle";
import { Spatula } from "apparatus/library/spatula";
import { GlassReagentBottle } from "apparatus/library/storage/glass_reagent_bottle";
import { LiquidReagentBottle } from "apparatus/library/storage/liquid_reagent_bottle";
import { PlasticReagentBottle } from "apparatus/library/storage/plastic_reagent_bottle";
import { BuretteClamp } from "apparatus/library/structure/burette_clamp";
import { CapsulePill } from "apparatus/library/substances/capsule_pill";
import { Needle, Syringe } from "apparatus/library/syringe";
import { TestTubeRack } from "apparatus/library/test_tube_rack";
import { Timer } from "apparatus/library/timer";
import { OsmosisTube } from "apparatus/library/transportation/osmosis_tube";
import { RubberTube } from "apparatus/library/transportation/rubber_tube";
import { SchlenkLines } from "apparatus/library/transportation/schlenk_lines";
import { VenturiTube } from "apparatus/library/transportation/venturi_tube";
import { Tripod } from "apparatus/library/tripod";
import { Tube, TubeBend } from "apparatus/library/tubes";
import { DryingTube } from "apparatus/library/tubes/drying_tube";
import { VolumetricFlask } from "apparatus/library/volumetric_flask";
import { WatchGlass } from "apparatus/library/watch_glass";
import { WireGauze } from "apparatus/library/wire_gauze";
import { FreehandDrawing } from "freehand/apparatus";
import { Kettle } from "heating/kettle";
import clonedeep from "lodash.clonedeep";
import { ID } from "store/id";
import { Bottle } from "./accessories/bottle";
import { DropCounter } from "./accessories/drop_counter";
import { Faucet } from "./accessories/faucet";
import { FeltTipPen } from "./accessories/felt_tip_pen";
import { GHSHazardSymbol } from "./accessories/ghs_hazard_symbol";
import { GlassStopper } from "./accessories/glass_stopper";
import { Glove } from "./accessories/glove";
import { PlasticJointClip } from "./accessories/joint_clip";
import { Microscope } from "./accessories/microscope";
import { Pencil } from "./accessories/pencil";
import { PhPaper, PhPaperPack } from "./accessories/ph_paper";
import { Beaker, DisplacementBeaker } from "./beaker";
import { BuchnerFunnel } from "./buchner";
import { BunsenBurner } from "./bunsen";
import { Clamp, FrontClamp, Ring } from "./clamp";
import { ColdFinger } from "./coldfinger";
import { GasCylinder } from "./container/gas_cylinder";
import { PearFlask } from "./container/pear_flask";
import { ThistleTube } from "./container/thistle";
import { Cylinder } from "./cylinder";
import { Beads } from "./decorative/beads";
import { Bubbles } from "./decorative/bubbles";
import { Crystals } from "./decorative/crystals";
import { Flame } from "./decorative/flame";
import { IceCube } from "./decorative/ice_cube";
import { Label } from "./decorative/label";
import { Powder } from "./decorative/powder";
import { FumeHood } from "./devices/fume_hood";
import { Sonicator } from "./devices/sonicator";
import { DistillationFlask } from "./distillation/distillation_flask";
import { DistillationHead } from "./distillation/distillation_head";
import { GrahamCondenser2 } from "./distillation/graham";
import { WaterlessCondenser } from "./distillation/waterless_condenser";
import { ConicalFlask, ConicalFlaskWithSideArm } from "./flask";
import { Beehive } from "./heating/beehive";
import { CalorimetryCup } from "./heating/calorimetry_cup";
import { ClayTriangle } from "./heating/clay_triangle";
import { HeatingMantle, SoftHeatingMantle } from "./heating/mantle";
import { SandBath } from "./heating/sandbath";
import { SpiritLamp } from "./heating/spirit_lamp";
import { Tongs } from "./heating/tongs";
import { Lamp } from "./lamp";
import { LiveEditApparatus } from "./live_apparatus";
import { Conductimeter } from "./measurement/conductimeter";
import { DigitalThermometer } from "./measurement/digital_themormeter";
import { PhProbe } from "./measurement/ph_probe";
import { PipetteBottle } from "./measurement/pipette_bottle";
import { PetriDish } from "./petri";
import { BoilingFlask, RoundBottomFlask } from "./roundBottomFlask";
import { SeparatoryFunnel } from "./separatory";
import { Cuboid } from "./shapes/cuboid";
import { Cylinder as Cylinder3d } from "./shapes/cylinder";
import { Sphere } from "./shapes/sphere";
import { SpottingTile } from "./spotting_tile";
import { StirringRod } from "./stirringRod";
import { BuretteHolder } from "./structure/burette_holder";
import { LabJack } from "./structure/jack";
import { Table } from "./table";
import { Tank } from "./tank";
import { TestTube, TestTubeWithSideArm } from "./testTube";
import { Thermometer } from "./thermometer";
import { ThieleTube } from "./thiele";
import { DialysisTube } from "./transportation/dialysis";
import { DroppingFunnel } from "./transportation/drop_funnel";
import { AdapterTube, FourWayCrossing, STube, ThreeWayCrossing, UTube } from "./tubes";
import { WashBottle } from "./utility/wash_bottle";
export function createApparatus(type, position) {
    var properties = apparatusClass(type).properties;
    var liquidData = properties.defaultLiquidData || DEFAULT_LIQUID_DATA;
    return {
        id: ID.mint("apparatus"),
        type: type,
        x: position.x,
        y: position.y,
        rotation: properties.defaultRotation || 0,
        selected: false,
        flipped: false,
        appearance: __assign({}, properties.defaultAppearance),
        liquid: properties.canContainLiquids ? clonedeep(liquidData) : undefined
    };
}
export function apparatusClass(id) {
    switch (id) {
        case "__live edit__": return LiveEditApparatus;
        case "__brk": return Borked;
        case "__raster image__": return RasterImage;
        case "__freehand": return FreehandDrawing;
        case "beaker": return Beaker;
        case "test tube": return TestTube;
        case "test tube with side arm": return TestTubeWithSideArm;
        case "thermometer": return Thermometer;
        case "conical flask": return ConicalFlask;
        case "table": return Table;
        case "petri dish": return PetriDish;
        case "round bottom flask": return RoundBottomFlask;
        case "retort stand": return RetortStand;
        case "tank": return Tank;
        case "cylinder": return Cylinder;
        case "stirring rod": return StirringRod;
        case "clamp": return Clamp;
        case "clamp front": return FrontClamp;
        case "bunsen": return BunsenBurner;
        case "bung": return Bung;
        case "volumetric flask": return VolumetricFlask;
        case "reagent bottle": return ReagentBottle;
        case "balance": return Balance;
        case "pipette": return Pipette;
        case "tube bend": return TubeBend;
        case "tube": return Tube;
        case "eye": return Eye;
        case "dropper pipette": return Dropper;
        case "magnetic stirrer-heater": return MagneticStirrer;
        case "glass wool": return GlassWool;
        case "tripod": return Tripod;
        case "hot plate": return HotPlate;
        case "magnetic stir bar": return StirBar;
        case "liebig condenser": return LiebigCondenser;
        case "burette": return Burette;
        case "pump": return Pump;
        case "fractionating column": return FractionatingColumn2;
        case "filter funnel": return FilterFunnel;
        case "drop": return Drop;
        case "displacement beaker": return DisplacementBeaker;
        case "filter paper": return FilterPaper;
        case "fluted filter paper": return FlutedFilterPaper;
        case "boiling flask": return BoilingFlask;
        case "wire gauze": return WireGauze;
        case "spatula": return Spatula;
        case "gas jar": return GasJar;
        case "pestle": return Pestle;
        case "mortar": return Mortar;
        case "test tube rack": return TestTubeRack;
        case "thiele tube": return ThieleTube;
        case "crucible": return Crucible;
        case "cuvette": return Cuvette;
        case "crucible cover": return CrucileCover;
        case "desiccator": return Desiccator;
        case "evaporating dish": return EvaporatingDish;
        case "lamp": return Lamp;
        case "timer": return Timer;
        case "label": return Label;
        case "spotting tile": return SpottingTile;
        case "retort ring": return Ring;
        case "plastic pipette": return PlasticPipette;
        case "watch glass": return WatchGlass;
        case "nail": return Nail;
        case "bench mat": return BenchMat;
        case "wooden splint": return Splint;
        case "capillary tube": return CapillaryTube;
        case "gas wash bottle": return GasWashBottle;
        case "4-way crossing": return FourWayCrossing;
        case "3-way crossing": return ThreeWayCrossing;
        case "u-bent tube": return UTube;
        case "s-bent tube": return STube;
        case "glove": return Glove;
        case "goggles": return Goggles;
        case "gas syringe": return GasSyringe;
        case "tube adapter": return AdapterTube;
        case "ruler": return Ruler;
        case "allihn": return AllihnCondenser2;
        case "graham": return GrahamCondenser2;
        case "cold finger": return ColdFinger;
        case "conical flask with side arm": return ConicalFlaskWithSideArm;
        case "buchner": return BuchnerFunnel;
        case "separatory funnel": return SeparatoryFunnel;
        case "tongs": return Tongs;
        case "bubbles": return Bubbles;
        case "crystals": return Crystals;
        case "sand bath": return SandBath;
        case "beehive stand": return Beehive;
        case "clay triangle": return ClayTriangle;
        case "digital thermometer": return DigitalThermometer;
        case "calorimetry cup": return CalorimetryCup;
        case "boiling tube": return BoilingTube;
        case "boiling tube with sidearm": return BoilingTubeWithSideArm;
        case "wash bottle": return WashBottle;
        case "metal strip": return MetalStrip;
        case "thistle tube": return ThistleTube;
        case "pipette bottle": return PipetteBottle;
        case "ice cube": return IceCube;
        case "spirit lamp": return SpiritLamp;
        case "powder": return Powder;
        case "heating mantle": return HeatingMantle;
        case "waterless condenser": return WaterlessCondenser;
        case "burette holder": return BuretteHolder;
        case "ph probe": return PhProbe;
        case "conductimeter": return Conductimeter;
        case "ghs hazard": return GHSHazardSymbol;
        case "flame": return Flame;
        case "peg": return Peg;
        case "test tube holder": return TestTubeHolder;
        case "centrifuge tube": return CentrifugeTube;
        case "eppendorf tube": return EppendorfTube;
        case "lab jack": return LabJack;
        case "gas cylinder": return GasCylinder;
        case "distillation head": return DistillationHead;
        case "dropping funnel": return DroppingFunnel;
        case "plastic joint clip": return PlasticJointClip;
        case "glass stopper": return GlassStopper;
        case "distillation flask": return DistillationFlask;
        case "pear-shaped flask": return PearFlask;
        case "microscope": return Microscope;
        case "receiver adaptor": return ReceiverAdaptor;
        case "schlenk flask": return SchlenkFlask;
        case "dean-stark trap": return DeanStarkTrap;
        case "croc clips": return CrocodileClips;
        case "soxhlet extractor": return SoxhletExtractor;
        case "rectangle": return Rectangle;
        case "ellipse": return Ellipse;
        case "schlenk lines": return SchlenkLines;
        case "particles": return Particles;
        case "plastic reagent bottle": return PlasticReagentBottle;
        case "cork ring": return CorkRing;
        case "rotavap": return Rotavap;
        case "nichrome loop": return NichromeLoop;
        case "leaf": return Leaf;
        case "potato": return Potato;
        case "hofmann": return Hofmann;
        case "tlc plate": return TLCPlate;
        case "tlc spot": return TLCSpot;
        case "seed": return Seed;
        case "petri top": return PetriTop;
        case "cotton wool": return CottonWool;
        case "agar": return Agar;
        case "microscope slide": return MicroscopeSlide;
        case "cover slip": return CoverSlip;
        case "potometer": return Potometer;
        case "tweezers": return Tweezers;
        case "plant shoot": return PlantShoot;
        case "shoot": return Shoot;
        case "pot": return PlantPot;
        case "pond weed": return PondWeed;
        case "sun": return Sun;
        case "quadrat": return Quadrat;
        case "scalpel": return Scalpel;
        case "dirt": return Dirt;
        case "bacteria": return Bacteria;
        case "cross": return Cross;
        case "desk lamp": return DeskLamp;
        case "splash head": return SplashHead;
        case "tick": return Tick;
        case "arrow": return Arrow;
        case "beads": return Beads;
        case "water bath": return WaterBath;
        case "tape measure": return TapeMeasure;
        case "root": return Root;
        case "syringe": return Syringe;
        case "bug": return Bug;
        case "3dcylinder": return Cylinder3d;
        case "scissors": return Scissors;
        case "tap": return Tap;
        case "curve": return Curve;
        case "curved arrow": return CurvedArrow;
        case "stir arrow": return StirArrow;
        case "line": return Line;
        case "cowtype": return CowType;
        case "oil bubbler": return OilBubbler;
        case "vigreux": return Vigreux;
        case "mohr": return MohrPipette;
        case "micropipette": return Micropipette;
        case "microplate": return Microplate;
        case "suction pear": return SuctionPear;
        case "steel wool": return SteelWool;
        case "hoffman clamp": return HoffmanClamp;
        case "cell flask": return CellCultureFlask;
        case "burette clamp": return BuretteClamp;
        case "battery": return Battery;
        case "camera": return Camera;
        case "videocamera": return VideoCamera;
        case "thermos": return ThermosFlask;
        case "stick lighter": return StickLighter;
        case "torch burner": return TorchBurner;
        case "hydrometer": return Hydrometer;
        case "balloon": return Balloon;
        case "star": return Star;
        case "streaked bacteria": return StreakedBacteria;
        case "manometer": return Manometer;
        case "osmosis tube": return OsmosisTube;
        case "dialysis tube": return DialysisTube;
        case "faucet": return Faucet;
        case "adhesive tape": return Sellotape;
        case "sonicator": return Sonicator;
        case "bottle": return Bottle;
        case "cuboid": return Cuboid;
        case "ph paper": return PhPaper;
        case "ph paper pack": return PhPaperPack;
        case "drop counter": return DropCounter;
        case "sphere": return Sphere;
        case "fume hood": return FumeHood;
        case "triangle": return Triangle;
        case "pencil": return Pencil;
        case "felt tip pen": return FeltTipPen;
        case "spectrophotometer": return Spectrophotometer;
        case "spectrophotometer-s": return SpetrophotometerSchematic;
        case "candle": return Candle;
        case "triple beam balance": return TripleBeamBalance;
        case "smoke": return Smoke;
        case "protractor": return Protractor;
        case "beam balance": return BeamBalance;
        case "oxsat probe": return OxSatProbe;
        case "powerpack": return PowerPack;
        case "spring": return Spring;
        case "mirror": return Mirror;
        case "combustion spoon": return CombustionSpoon;
        case "magnet": return Magnet;
        case "voltmeter-s": return SimpleVoltmeter;
        case "ammeter-s": return SimpleAmmeter;
        case "pulley": return Pulley;
        case "cable": return Cable;
        case "glass block": return GlassBlock;
        case "slotted weight": return SlottedWeight;
        case "raybox": return RayBox;
        case "choice chamber": return ChoiceChamber;
        case "vernier": return VernierCaliper;
        case "digital voltmeter": return DigitalVoltmeter;
        case "digital ammeter": return DigitalAmmemter;
        case "solenoid": return Solenoid;
        case "centrifuge": return Centrifuge;
        case "weight boat": return WeightBoat;
        case "paperclip": return PaperClip;
        case "trolley": return Trolley;
        case "ramp": return Ramp;
        case "light gate": return LightGate;
        case "tlc chamber": return TLCChamber;
        case "piston chamber": return PistonChamber;
        case "magnifying glass": return MagnifyingGlass;
        case "resistor": return Resistor;
        case "knife switch": return KnifeSwitch;
        case "multimeter": return Multimeter;
        case "led": return Led;
        case "gummy bear": return GummyBear;
        case "ray": return Ray;
        case "lens": return Lens;
        case "eureka": return EurekaCan;
        case "curved mirror": return CurvedMirror;
        case "prism": return Prism;
        case "needle": return Needle;
        case "mouse": return Mouse;
        case "dimroth": return DimorthCondenser;
        case "septum seal": return SeptumSeal;
        case "hand": return Hand;
        case "length label": return LengthLabel;
        case "angle label": return AngleLabel;
        case "soft mantle": return SoftHeatingMantle;
        case "autotransformer": return Autotransformer;
        case "computer": return Computer;
        case "spec20": return Spectronic20;
        case "reagent2": return LiquidReagentBottle;
        case "eudiometer": return Eudiometer;
        case "oven": return Oven;
        case "glass lab bottle": return GlassReagentBottle;
        case "daisy": return Daisy;
        case "tree": return Tree;
        case "salt bridge": return SaltBridge;
        case "electrode": return Electrode;
        case "match": return Match;
        case "dynamometer": return Dynamometer;
        case "venturi tube": return VenturiTube;
        case "lab coat": return LabCoat;
        case "pycnometer": return Pycnometer;
        case "kettle": return Kettle;
        case "ice bath": return IceBath;
        case "soda can": return SodaCan;
        case "vacuum pump": return VacuumPump;
        case "rubber tube": return RubberTube;
        case "isocube": return IsoCuboid;
        case "drying tube": return DryingTube;
        case "litmus paper": return LitmusPaper;
        case "claisen": return ClaisenHead;
        case "capsule pill": return CapsulePill;
        case "stirbar2": return EllipticalStirringBar;
        case "half-cell tube": return HalfCellTube;
        case "snail": return Snail;
        case "autosampler vial": return AutosamplerVial;
        case "syringe filter": return SyringeFilter;
        case "spe cartridge": return SPECartridge;
        case "bulb2": return MountedBulb;
        case "motor": return Motor;
        case "capacitor": return Capacitor;
        case "capacitor2": return MountedCapacitor;
        case "switch": return MountedSwitch;
        case "buzzer": return MountedBuzzer;
        case "rheostat": return Rheostat;
        case "diode": return Diode;
        case "corkborer": return CorkBorer;
    }
}
