// Note that react menu styles have been manually copied over into react-menu.less
// Application entry point.
import { g } from "analytics";
import { createApparatus } from "apparatus/library";
import { registerBootstrapColorpickerExtensions } from "bootstrap_colorpicker_extensions";
import { Cookie } from "browser/cookie";
import { AddApparatus } from "command/basic";
import { commandProcessor } from "command/command";
import Flags from "flags";
import { t } from "i18next";
import { ConnectedApp } from "layout/app";
import { clog } from "log";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { addItems } from "store/actions";
// Redux components.
import { appStore } from "store/store";
// Import styles
import "../less/styles.less";
import { BOOTSTRAP_COLORPICKER_CSS_SELECTOR } from "./components/colorpicker";
// i18n
import "./i18n";
// Set up libraries and frameworks
import "./setup";
import { setUpToastr } from "./setup";
import { logIn, logOut } from "./store/actions";
import { Consts } from "./vars";
/** App entry point. */
function main() {
    // Set up Toastr options.
    setUpToastr();
    // Polyfill fetch for IE11.
    if (window.fetch === undefined) {
        clog("Polyfilling fetch...");
        window.fetch = window['unfetch'];
    }
    // Initialise app.
    // Put the Provider on the outside and then place the connected app inside it.
    var app = React.createElement(ConnectedApp);
    var provider = React.createElement(Provider, { store: appStore }, app);
    var root = createRoot(document.getElementById("app"));
    root.render(provider);
    // TODO: Handle case where the store is empty to begin with
    if (!Consts.isDev) {
        registerUnloadListener();
    }
    else {
        var toAdd = [
            "__live edit__",
            "__raster image__",
            "length label",
            "angle label",
            "particles",
            "switch",
            "buzzer",
            "rheostat",
            "diode",
            "dean-stark trap",
            "corkborer",
        ];
        appStore.dispatch(addItems(toAdd.map(function (item, i) {
            return createApparatus(item, { x: (i % 4) * 150 + 350, y: Math.floor(i / 4) * 150 + 150 });
        })));
    }
    // Log language
    setTimeout(function () {
        var lang = t("currentLanguage");
        clog("Current language: " + lang);
        g("DisplayLanguage", lang);
    }, 5000);
    registerBootstrapColorpickerExtensions();
    // Fix      use up outside bootstrap colorpicker not ending slider drag.
    $("body").on("mouseup", function () {
        $("" + BOOTSTRAP_COLORPICKER_CSS_SELECTOR).each(function (i, el) {
            var data = $(el).data();
            if (!data)
                return;
            if (!data.colorpicker)
                return;
            data.colorpicker.sliderHandler.released();
        });
    });
}
// function printNumberOfCustomisations() {
//     let optionsCount = 0
//     for (const spec of apparatusSpecListWithNames) {
//         const klass = apparatusClass(spec.type)
//         optionsCount += klass.properties.appearanceSpecs.filter(s => s.tier === "boost").length
//     }
//     console.log(optionsCount)
// }
/** Registers an event listener to ask user before closing Chemix. */
function registerUnloadListener() {
    window.addEventListener('beforeunload', function (e) {
        if (commandProcessor.hasDirtyChanges()) {
            // Ask user to confirm before exiting.
            e.preventDefault();
            e.returnValue = '';
        }
        else {
            // Exit freely.
            // The absence of a returnValue property on the event will guarantee the browser unload happens
            delete e['returnValue'];
        }
    });
}
// Bind to on Ready
function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    }
    else {
        document.addEventListener('DOMContentLoaded', fn, { once: true });
    }
}
ready(main);
// Allow hacking!
if (Consts.isDev) {
    var fakeLogin = function (firstTimeLogin) {
        appStore.dispatch(logIn({
            jwtToken: "f", provider: "google", firstTimeLogin: firstTimeLogin, tier: "boost",
            user: { id: "0", name: "Chemix User", handle: "fake.user@Example.com", email: "fake.user@example.com", avatarUrl: "./images/200.png" }
        }));
    };
    window["__FLAGS__"] = Flags;
    window["__addApparatus__"] = function (type) {
        appStore.dispatch(addItems([createApparatus(type, { x: 250, y: 300 })]));
    };
    window["__fakeLogin__"] = fakeLogin;
    window["__fakeLogout__"] = function () {
        appStore.dispatch(logOut());
    };
    window["__enableSuperZoom__"] = function () { return $('#zoom-slider').slider({ max: 6 }); };
    window["__store__"] = function () { return appStore; };
    if (window.location.hash === "#login")
        setTimeout(fakeLogin, 100);
    window["__resetWelcomeScreen__"] = function () { return Cookie.write("WELCOME_SCREEN_SHOWN", "0"); };
}
// For crash testing.
;
window["__tonngokhong__"] = function () {
    commandProcessor.execute(new AddApparatus([createApparatus("__brk", { x: 250, y: 300 })]));
};
